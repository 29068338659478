import React, { useState, useEffect } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Image,
    Text,
    Box,
} from "@chakra-ui/react";
import partenerImg from "../../assets/handShake_img.jpg";
import "./popUp.css";

const RegionalPartnerModal = () => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const visitInfo = JSON.parse(localStorage.getItem("visitInfo"));
    
        if (visitInfo) {
            const { timestamp } = visitInfo;
            const currentTime = new Date().getTime();
    
            // Check if 1 hour has passed (3600000 milliseconds)
            if (currentTime - timestamp > 3600000) {
                setIsOpen(true);
                localStorage.setItem("visitInfo", JSON.stringify({
                    hasVisited: true,
                    timestamp: currentTime
                }));
            }
        } else {
            // First time visiting
            setIsOpen(true);
            localStorage.setItem("visitInfo", JSON.stringify({
                hasVisited: true,
                timestamp: new Date().getTime()
            }));
        }
    
        // Reset the visitInfo after 1 hour
        const timer = setTimeout(() => {
            localStorage.removeItem("visitInfo");
        }, 3600000);
    
        // Clean up the timer if the component unmounts
        return () => clearTimeout(timer);
    }, []);
    

    const handleRedirect = () => {
        window.open(
            "https://docs.google.com/forms/d/e/1FAIpQLScGtwlPlpSBxxBoVzLsqeJZaiItWnBiUF8bBoLFI2mjxp0ffg/viewform",
            "_blank"
        );
    };

    const handleClose = () => setIsOpen(false);

    return (
        <Modal isOpen={isOpen} onClose={handleClose} isCentered>
            <ModalOverlay />
            <ModalContent borderRadius="10px" className="custom-modal">
                <ModalCloseButton size="md" className="custom-modal__close-btn" />

                <Image
                    src={partenerImg}
                    alt="Be Our Regional Partner"
                    className="custom-modal__image"
                />
                <ModalBody>
                    <Box textAlign="center" p={4}>
                        <Text
                            fontSize={{ base: "24px", md: "30px" }}
                            fontWeight="bold"
                            mb={4}
                            className="custom-modal__header"
                        >
                            Be Our Regional Partner !
                        </Text>
                        <hr />
                        <Button
                            colorScheme="blue"
                            size="md"
                            mt={5}
                            onClick={handleRedirect}
                            width="50%"
                            fontSize={{ base: "18px", md: "20px" }}
                            className="custom-modal__button"
                        >
                            Apply Now
                        </Button>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>

    );
};

export default RegionalPartnerModal;
