import React from "react";
// import Banner from "../../assets/banner.png";
import Banner from "../../assets/bannerImages/download.webp";
import LeftImage from "../../assets/downloadImage/backleft.png";
import RightImage from "../../assets/downloadImage/backright.png";
import Image1 from "../../assets/downloadImage/step1.png";
import Image2 from "../../assets/downloadImage/step2.png";
import Image3 from "../../assets/downloadImage/step3.png";
import Image4 from "../../assets/downloadImage/step4.png";
import Mobile from "../../assets/homeImage/mobile.png";
import Google from "../../assets/homeImage/google.png";
import Apple from "../../assets/homeImage/apple.png";
import QR from "../../assets/homeImage/qr.png";
import {Link} from 'react-router-dom'
import {
  Box,
  Flex,
  Grid,
  Heading,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";

const Download = () => {
  return (
    <Box>
      {/* <Image
           width="100%"
           height={{ base: "145px", md: "514px"  }}
           backgroundImage={`url(${Banner})`}
           backgroundSize="cover"
           backgroundPosition="center"
           objectFit="cover"
           
      /> */}
      <img src={Banner} style={{widht:"100%", display:"block",height:"auto",padding:"0", margin:"0"}}/>
      <Box
        width="100%"
        height="100%"
        position="relative"
        backgroundColor="#EFEFEF"
      >
        <Text
          fontFamily="Montserrat"
          fontSize="35px"
          fontWeight="500"
          textAlign="center"
          pt="20"
        >
          Download the Ezivote App
        </Text>
        <Text
          fontFamily="Montserrat"
          fontSize="20px"
          fontWeight="400"
          textAlign="justify"
          padding="3rem"
        >
          Ready to embark on your political journey? Download the Ezivote app
          now and experience the thrill of shaping the nation's destiny from the
          palm of your hand!
        </Text>
        <Image
          src={LeftImage}
          position="absolute"
          left="0"
          top="50%"
          transform="translateY(-50%)"
          height="100%"
          
        />
        <Image
          src={RightImage}
          position="absolute"
          right="0px"
          top="50%"
          transform="translateY(-50%)"
          height="100%"
        />
        {/* <Grid
          templateColumns={{
            base: "1fr",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
          }}
          gap="4"
        //   mt="8"
          paddingLeft={9}
        // padding="4"
        >
          <Image src={Image1} sx={{padding:"36px"}}/>
          <Image src={Image2} sx={{padding:"20px"}}/>
          <Image src={Image3} sx={{padding:"20px"}}/>
          <Image src={Image4} sx={{padding:"36px"}}/>
        </Grid> */}

        <Grid
         templateColumns={{
          base:"1fr",
          md:"repeat(2, 1fr)",
          lg:"repeat(4,1fr)"
         }}
         width="100%"
         >
           <Box backgroundColor="#EFEFEF" padding="18px">
            {/* // making width 200% of images will make image align with container on large device */}
            <VStack alignItems="center">
              <Heading
                as="h2"
                mb={2}
                fontFamily="Montserrat"
                fontSize="1rem"
                fontWeight="500"
                lineHeight="37px"
                textAlign="center"
              >
              Step 1
              </Heading>
              <Text
               fontFamily="Montserrat"
               fontSize="20px"
               fontWeight="500"
               lineHeight="28px"
               color="black"
               textAlign="center"
              >
                Click "<Text display="inline" color="#2E74C6">Download</Text>" button to continue.
              </Text>
            <Image src={Image1} alt="Image 1" alignSelf="center" zIndex="1" />
            </VStack>
          </Box>
          <Box  backgroundColor="#EFEFEF" padding="18px">
            {/* // making width 200% of images will make image align with container on large device */}
            <VStack  alignItems="center">
              <Heading
               as="h2"
               mb={2}
               fontFamily="Montserrat"
               fontSize="1rem"
               fontWeight="500"
               lineHeight="37px"
               textAlign="center"
              
              >
                Step 2
              </Heading>
              <Text
                fontFamily="Montserrat"
                fontSize="20px"
                fontWeight="500"
                lineHeight="28px"
                color="black"
                textAlign="center"
              >
               Click on "<Text display="inline" color="#2E74C6">Download Anyway</Text>" to start downloading.
              </Text>
            <Image src={Image2} alt="Image 1" alignSelf="center" zIndex="1" />
            </VStack>
          </Box>
          <Box  backgroundColor="#EFEFEF" padding="18px">
            {/* // making width 200% of images will make image align with container on large device */}
            <VStack  alignItems="center">
              <Heading
                as="h2"
                mb={2}
                fontFamily="Montserrat"
                fontSize="1rem"
                fontWeight="500"
                lineHeight="37px"
                textAlign="center"
              >
              Step 3
              </Heading>
              <Text
                fontFamily="Montserrat"
                fontSize="20px"
                fontWeight="500"
                lineHeight="28px"
                color="black"
                textAlign="center"
              >
               Turn on "<Text display="inline" color="#2E74C6">Allow Apps</Text>" from this source.
              </Text>
            <Image src={Image3} alt="Image 1" alignSelf="center" zIndex="1" />
            </VStack>
          </Box>
          <Box backgroundColor="#EFEFEF" padding="18px">
            {/* // making width 200% of images will make image align with container on large device */}
            <VStack  alignItems="center">
              <Heading
               as="h2"
               mb={2}
               fontFamily="Montserrat"
               fontSize="1rem"
               fontWeight="500"
               lineHeight="37px"
               textAlign="center"
              >
                Step 4
              </Heading>
              <Text
                fontFamily="Montserrat"
                fontSize="20px"
                fontWeight="500"
                lineHeight="28px"
                color="black"
                textAlign="center"
              >
               Click on "<Text display="inline" color="#2E74C6">Install</Text>" to play games and earn real cash.
              </Text>
              <Image src={Image4} alt="Image 1" alignSelf="center" zIndex="1" />
            </VStack>
          </Box>
         </Grid>
     
     
     
      </Box>
      <Box 
       sx={{gap:"4"}}
       padding="16"
      >
        <Text
          fontFamily="Montserrat"
          fontSize="35px"
          fontWeight="500"
          textAlign="start"
          //   mt={20}
        //   mb={15}
        >
          Android Users
        </Text>
        <Text
          fontFamily="Montserrat"
          fontSize="20px"
          fontWeight="400"
          textAlign="start"
          mb="10"
        >
          Get started on your Android device by downloading Ezivote from the
          Google Play Store. Click the button below to begin your adventure:
        </Text>

        <Text
          fontFamily="Montserrat"
          fontSize="35px"
          fontWeight="500"
          textAlign="start"
        //   mb="40px"
        >
          iOS Users
        </Text>
        <Text
          fontFamily="Montserrat"
          fontSize="20px"
          fontWeight="400"
          textAlign="start"
        //   mb="40px"
        >
          Coming soon to the App Store! Stay tuned for updates on when Ezivote
          will be available for iOS devices
        </Text>
        <Text
          fontFamily="Montserrat"
          fontSize="20px"
          fontWeight="400"
          textAlign="start"
        >
          Join a community of passionate gamers and political enthusiasts as you
          compete, strategize, and conquer in the ultimate political fantasy
          gaming experience. Don't miss out- download Ezivote today and let the
          games begin!
        </Text>
      </Box>

      <Box display="flex"  justifyContent="center" alignItems="center" position="relative"
      height="'100%"
      width="100%"
      >   
        <Box
          marginRight={{ md: "650px" }}
          // maxWidth="600px"
          width="1366px"
          padding="0 20px"
          textAlign="left"
          height={{base:"767px", md:"500px"}}
        > 
       
        <Image
          src={Mobile}
          alt="mobile"
          position="absolute"
          top={{base:"60%", md:"-30px"}}
          // top="-30%"
          width={{base:"67%", sm:"40%", md:"38%"}}
          right={{base:"20%", md:"6%"}}
          maxWidth="100%"
         
        />
       
        <Box paddingLeft={{base:"0.5rem", md:"2rem"}}>
          <Heading
            fontSize="40px"
            fontWeight="600"
            fontFamily="Montserrat"
            // marginBottom="60px"
          >
            GET THE APP
          </Heading>
          <Box marginBottom="40px">
            <Text
              fontFamily="Roboto"
              fontSize="20px"
              marginBottom="1rem"
            >
              &#9733; Play with over 1.5 Lakhs users
            </Text>
            <Text
              fontFamily="Roboto"
              fontSize="20px"
              marginBottom="1rem"
            >
              &#9733; Invite your friends & collect big rewards
            </Text>
            <Text
              fontFamily="Roboto"
              fontSize="20px"
              marginBottom="1rem"
            >
              &#9733; Play more, earn coins & get exciting rewards
            </Text>
          </Box>
          <Flex alignItems="center" marginBottom="60px">
            <Link to="https://play.google.com/store/apps/details?id=io.ionic.ezivote" target="_blank" cursor="pointer">
            <Image src={Google} width={{base:"75%"}} alt="google" marginRight="1rem" />
          </Link>
          <Link 
          // to="https://m.facebook.com/ezivote/" target="_blank" 
          cursor="pointer">
            <Image src={Apple} width={{base:"80%"}} alt="apple" />
          </Link>
          </Flex>
          <Flex alignItems="center" marginBottom="40px">
            <Image src={QR} width={{base:"10%",}}alt="qr code" marginRight="1rem" />
            <Text
              fontSize={{base:"0.7rem", md:"1.2rem"}}
              fontWeight="400"
              fontFamily="Montserrat"
            >
              Scan the QR Code to download the
              <Link to="https://m.facebook.com/ezivote/" target="_blank" cursor="pointer">
              <Text color="#3379CB" fontWeight="500">
                EziVote App
              </Text>
          </Link>
            </Text> 
          </Flex>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Download;
