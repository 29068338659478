import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import "../staticPage.css";

const Refund = () => {
    return (
        <Flex className="flex-container">
            <h5 className="heading" style={{ textAlign: "center" }}>
                Ezivote Refund and Cancellation Policy
            </h5>
            <Box className="box-container">

                <Text className="heading">
                    1. Overview
                </Text>
                <Text className="text-content">
                    At Ezivote, we strive to ensure our customers have a satisfying experience with our products and services. This Refund and Cancellation Policy outlines the circumstances under which refunds and cancellations will be provided.
                </Text>
            </Box>
            <Box className="box-container">
                <Text className="heading">
                    2. Eligibility for Refunds
                </Text>
                <Text className="heading">
                    2.1 Digital Products and Services
                </Text>
                <Text className="text-content">
                    Due to the nature of digital products and services, refunds will only be considered under the following circumstances:
                </Text>
                <Text className="text-content">
                    <strong>• Technical Issues :</strong> If you experience technical issues that prevent you from accessing or using the purchased digital product or service, and our support team is unable to resolve the issue within 72 hours.<br />
                    <strong>• Misrepresentation :</strong> If the digital product or service you received is substantially different from what was described on our website.
                </Text>
            </Box>
            <Box className="box-container">

                <Text className="heading">
                    2.2 In-Game Purchases
                </Text>
                <Text className="text-content">
                    Refunds for in-game purchases, including but not limited to virtual items, currency, and subscriptions, will only be provided if: </Text>
                <Text className="text-content">
                    <strong>• Unauthorized Transactions :</strong> You can demonstrate that an unauthorized transaction occurred.<br />
                    <strong>• Technical Glitches :</strong> The purchase was made due to a technical glitch or error. </Text>
            </Box>
            <Box className="box-container">
                <Text className="heading">
                    3. Cancellation Policy
                </Text>
                <Text className="heading">
                    3.1 Subscription Services
                </Text>
                <Text className="text-content">
                    If you have purchased a subscription service, you may cancel your subscription at any time. The following conditions apply: </Text>
                <Text className="text-content">
                    <strong>• Monthly Subscriptions :</strong> Cancellations will take effect at the end of the current billing cycle. No refunds will be issued for the remainder of the billing period.<br />
                    <strong>• Annual Subscriptions :</strong> If you cancel within 14 days of purchase, you will receive a full refund. Cancellations made after 14 days will not be eligible for a refund, but you will retain access to the service until the end of the subscription term.
                </Text>
                <Text className="heading">
                    3.2 Pre-Order Cancellations
                </Text>
                <Text className="text-content">
                    Pre-orders for upcoming games or digital content can be canceled at any time before the release date for a full refund. Cancellations made after the release date will be subject to the standard refund policy. </Text>
            </Box>

            <Box className="box-container">
                <Text className="heading">
                    4. How to Request a Refund or Cancellation
                </Text>
                <Text className="heading">
                    To request a refund or cancellation, please follow these steps:
                </Text>

                <Text className="text-content">
                <strong>• Contact Support :</strong> Email our customer support team at contact@ezivote.in with your order details and the reason for the request.<br />
                <strong>• Provide Necessary Information :</strong> Include your order number, date of purchase, and any relevant screenshots or documentation.<br />
                <strong>• Wait for Response :</strong> Our support team will review your request and respond within 3-5 business days.
                </Text>
            </Box>
            <Box className="box-container">
                <Text className="heading">
                    5. Processing Refunds
                </Text>
                <Text className="heading">
                    If your refund request is approved, the following will apply:
                </Text>
                <Text className="text-content">
                    <strong>• Payment Method :</strong> Refunds will be processed using the original payment method used for the purchase.<br />
                    <strong>• Processing Time :</strong> Refunds may take 5-10 business days to appear on your statement, depending on your bank or payment provider.
                </Text>
            </Box>
            <Box className="box-container">
                <Text className="heading">
                    6. Changes to this Policy
                </Text>
                <Text className="text-content">
                    Ezivote reserves the right to modify this Refund and Cancellation Policy at any time. Any changes will be posted on this page, and the date of the latest revision will be indicated at the top of the policy.
                </Text>
            </Box>
            <Box className="box-container">
                <Text className="heading">
                    7. Contact Us

                </Text>
                <Text className="text-content">
                    If you have any questions or concerns about our Refund and Cancellation Policy, please contact us at contact@ezivote.in.
                    <br />
                    By using our website and services, you agree to this Refund and Cancellation Policy. Thank you for choosing Ezivote!
                </Text>
            </Box>
        </Flex>
    );
};

export default Refund;
