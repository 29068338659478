import React from "react";
import { Box, Flex, Image, Text, Button } from "@chakra-ui/react";
import logo from "../../assets/logo.png";
import footerImage from "../../assets/footer.png";
import { Link, useNavigate } from "react-router-dom";
import Google from "../../assets/homeImage/google.png";
import Apple from "../../assets/homeImage/apple.png";

const Footer = () => {
  const downloadFile = () => {
    // Replace 'your_file_url' with the actual URL of the file you want to download
    const fileUrl = "https://play.google.com/store/apps/details?id=io.ionic.ezivote";
    // Create a temporary anchor element
    const link = document.createElement("a");
    link.href = fileUrl;
    // Set the file name (you can extract it from the URL or provide a custom name)
    link.download = "filename";
    // Append the anchor to the document body
    document.body.appendChild(link);
    // Trigger the download
    link.click();
    // Clean up by removing the anchor
    document.body.removeChild(link);
  };

  const navigate = useNavigate();
  return (
    <Box position="relative">
      <Image
        src={footerImage}
        alt="Footer Background"
        position="absolute"
        zIndex="-1"
        width="100%"
        height="100%"
        objectFit="cover"
      />
      <Flex
        flexDirection={{ base: "column", md: "row" }}
        justifyContent="space-between"
        alignItems="center"
        position="relative"
        fontFamily="Montserrat"
      >
        {/* Logo and Text */}
        <Box
          flex="1"
          maxW={{ base: "80%", md: "100%" }}
          width="100%"
          align="center"
          justify="center"
          flexDirection="column"
          p={{ base: "2", md: "6" }}
        >
          <Image src={logo} alt="Logo" width={{ base: "35%", md: "40%" }} />
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "0.8rem", md: "1rem" }}
            fontWeight="400"
            textAlign={{ base: "justify", md: "justify" }}
            //  color='#696969'
          >
            A political fantasy gaming application that entertains and educates
            the masses about elections. Make your dream cabinet with multiple
            virtual elections, win exciting prizes, and remain informed about
            every political happening across the globe.
          </Text>
        </Box>

        {/* Election List */}
        <Box
          flex="1"
          maxW={{ base: "100%", md: "354px" }}
          width="100%"
          align="center"
          justify="center"
          flexDirection="column"
          // spacing={2}
          alignItems="flex-start"
          // p={{ base: '4', md: '6' }}
          mb={{ base: "0.5rem", md: "55" }}
        >
          <Text
            fontWeight="bold"
            // mb={{base:"0.4rem", md:"7"}}
          >
            Election List
          </Text>
          <Text
            onClick={() => {
              navigate("/election-list");
            }}
          >
            <Link to="/election-list">Live Election</Link>
          </Text>
          <Text
            onClick={() => {
              navigate("/election-list");
            }}
          >
            <Link to="/election-list">Upcoming Election</Link>
          </Text>
          <Text
            onClick={() => {
              navigate("/election-list");
            }}
          >
            <Link to="/election-list">Past Election</Link>
          </Text>
        </Box>

        {/* How to Play */}
        <Box
          flex="1"
          maxW={{ base: "100%", md: "354px" }}
          width="100%"
          align="center"
          justify="center"
          flexDirection="column"
          // spacing={2}
          mb={{ base: "1rem", md: "1rem" }}
          alignItems="flex-start"
          // p={{ base: '4', md: '6' }}
        >
          <Text fontWeight="bold">Important Links</Text>
          <Text
            onClick={() => {
              navigate("/");
            }}
          >
            <Link to="/">Game Rules</Link>
          </Text>
          <Text
            onClick={() => {
              navigate("/");
            }}
          >
            <Link to="/">Step</Link>
          </Text>

          <Link to="/points">Point Distribution</Link>

          <Text
            onClick={() => {
              navigate("/news");
            }}
          >
            <Link to="/blog">News</Link>
          </Text>
        </Box>

        {/* App Download */}
        <Box
          flex="1"
          maxW={{ base: "100%", md: "354px" }}
          width="100%"
          align="center"
          justify="center"
          flexDirection="column"
          // spacing={2}
          alignItems="flex-start"
          // p={{ base: '4', md: '6' }}
          mb={{ base: "0.4rem", md: "7" }}
        >
          <Text
            fontWeight="bold"
            // mb={{base:"0.4rem", md:"7"}}
          >
            Download Apk
          </Text>
          <Link onClick={downloadFile} cursor="pointer">
            <Button
              colorScheme="blue"
              variant="solid"
              // mt={{base:"10px", md:"-25px"}}
              width={{base:"42%",md:"85%"}}
              fontSize={{ base: "15px", md: "16px" }}
              mb="5px"
              height={{base:"30px", md:"40px"}}
            >
              Click to Download
            </Button>
          </Link>

          <Flex justifyContent="center" alignItems="center">
            <Image
              src={Google}
              alt="google"
              width={{ base: "20%", md: "40%" }}
              cursor="pointer"
              mr={{ base: "10px", md: "20px" }}
              onClick={() => {
                window.open("https://play.google.com/store/apps/details?id=io.ionic.ezivote", "_blank");
              }}
            />
            <Image
              src={Apple}
              alt="apple"
              width={{ base: "20%", md: "40%" }}
              cursor="pointer"
              // onClick={() => {
              //   window.open("https://m.facebook.com/ezivote/", "_blank");
              // }}
            />
          </Flex>
        </Box>
      </Flex>
      <Flex
        height="55px"
        bg="#BEBEBE"
        justifyContent="space-between"
        alignItems="center"
        p={{ base: 4, md: 8 }}
        marginBottom="0px"
      >
        <Box>
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "0.7rem", md: "1rem" }}
            fontWeight={{ base: "500", md: "600" }}
            textAlign={{ base: "left" }}
          >
            <Link to="/policy-privacy">Privacy Policy</Link> |{" "}
            <Link to="terms-and-conditions">Terms and Conditions {" "}</Link>
            <Link to="/disclaimer">|{" "}Disclaimer{" "}</Link>
            <Link to="/refund">|{" "}Refund and Cancellation</Link>
          </Text>
        </Box>
        <Box>
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "0.7rem", md: "1rem" }}
            fontWeight={{ base: "500", md: "600" }}
            textAlign="right"
          >
            Ezivote ©2024. All Rights Reserved.
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default Footer;
