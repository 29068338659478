import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

const Policy = () => {
  return (
    <>
      <Flex
        align="center"
        justify="center"
        width="100%"
        maxWidth="1366px"
        margin="0 auto"
        padding="20px 0"
        flexDir="column"
        backgroundColor="#f4f4f4"
      >
        {/*About Content  */}
        <Box
          flex="2"
          width={{ base: "100%" }}
          padding={{ base: "20px", md: "20px" }}
        >
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "20px", md: "20px", lg: "25px" }}
            fontWeight={{ base: 500, md: 500, lg: 500 }}
            lineHeight="1.5"
            // textAlign={{ base: "center", md: "justify" }}
            marginTop={{ base: "10px", md: "10px" }}
            marginBottom={{ base: "10px", md: "10px" }}
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
          >
            Disclaimer
          </Text>
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "16px" }}
            fontWeight={400}
            lineHeight="24px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
            color="#696969"
          >
            Welcome to Ezivote! The content and services provided on this website are 
            intended for entertainment and informational purposes only. 
            While Ezivote aims to offer accurate and current information, 
            we make no representations or warranties of any kind, express or 
            implied, about the completeness, accuracy, reliability, suitability, 
            or availability with respect to the website or the information, products, 
            services, or related graphics contained on the website for any purpose. 
            Any reliance you place on such information is strictly at your own risk.
          </Text>
        </Box>
        <Box
          flex="2"
          width={{ base: "100%" }}
          padding={{ base: "20px", md: "20px" }}
        >
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "20px", md: "20px", lg: "25px" }}
            fontWeight={{ base: 500, md: 500, lg: 500 }}
            lineHeight="1.5"
            // textAlign={{ base: "center", md: "justify" }}
            marginTop={{ base: "10px", md: "10px" }}
            marginBottom={{ base: "10px", md: "10px" }}
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
          >
          Skill-Based Gaming
          </Text>
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "16px" }}
            fontWeight={400}
            lineHeight="24px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
            color="#696969"
          >
          Ezivote offers skill-based gaming experiences. The outcomes of these games
           are determined by players' skills and strategies. Participation in skill-based 
           gaming requires adherence to all relevant laws and regulations in your jurisdiction. 
           By using Ezivote, you confirm that you are in compliance with these laws and regulations 
           and that you are of legal age to participate in skill-based gaming.
          </Text>
        </Box>
        <Box
          flex="2"
          width={{ base: "100%" }}
          padding={{ base: "20px", md: "20px" }}
        >
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "20px", md: "20px", lg: "25px" }}
            fontWeight={{ base: 500, md: 500, lg: 500 }}
            lineHeight="1.5"
            // textAlign={{ base: "center", md: "justify" }}
            marginTop={{ base: "10px", md: "10px" }}
            marginBottom={{ base: "10px", md: "10px" }}
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
          >
            Responsible Gaming
          </Text>
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "16px" }}
            fontWeight={400}
            lineHeight="24px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
            color="#696969"
          >
            Gaming can be enjoyable and rewarding, but it also involves risks. 
            We urge all players to engage in gaming responsibly. Please set limits 
            on your gaming activities and expenditures, and seek professional 
            assistance if you believe you have a gaming problem.
          </Text>
        </Box>
        <Box
          flex="2"
          width={{ base: "100%" }}
          padding={{ base: "20px", md: "20px" }}
        >
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "20px", md: "20px", lg: "25px" }}
            fontWeight={{ base: 500, md: 500, lg: 500 }}
            lineHeight="1.5"
            // textAlign={{ base: "center", md: "justify" }}
            marginTop={{ base: "10px", md: "10px" }}
            marginBottom={{ base: "10px", md: "10px" }}
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
          >
            External Links
          </Text>
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "16px" }}
            fontWeight={400}
            lineHeight="24px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
            color="#696969"
          >
        Ezivote may contain links to external websites that are not maintained by or affiliated with us. 
        We do not endorse or assume any responsibility for the content, privacy policies, or practices 
        of any third-party websites.
          </Text>
        </Box>
        <Box
          flex="2"
          width={{ base: "100%" }}
          padding={{ base: "20px", md: "20px" }}
        >
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "20px", md: "20px", lg: "25px" }}
            fontWeight={{ base: 500, md: 500, lg: 500 }}
            lineHeight="1.5"
            // textAlign={{ base: "center", md: "justify" }}
            marginTop={{ base: "10px", md: "10px" }}
            marginBottom={{ base: "10px", md: "10px" }}
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
          >
            Restricted Area
          </Text>
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "16px" }}
            fontWeight={400}
            lineHeight="24px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
            color="#696969"
          >
          Fantasy Game Banned in 7 States: Will it be Banned Across All of India?
          Let's Find Out. States Currently Banned: Odisha, Andhra Pradesh, Telengana,
          Assam, Sikkim, Nagaland, Tamil Nadu
          </Text>
        </Box>
        <Box
          flex="2"
          width={{ base: "100%" }}
          padding={{ base: "20px", md: "20px" }}
        >
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "20px", md: "20px", lg: "25px" }}
            fontWeight={{ base: 500, md: 500, lg: 500 }}
            lineHeight="1.5"
            // textAlign={{ base: "center", md: "justify" }}
            marginTop={{ base: "10px", md: "10px" }}
            marginBottom={{ base: "10px", md: "10px" }}
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
          >
            Limitation of Liability
          </Text>
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "16px" }}
            fontWeight={400}
            lineHeight="24px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
            color="#696969"
          >
            In no event will Ezivote be liable for any loss or damage, 
            including without limitation, indirect or consequential loss or damage, 
            or any loss or damage whatsoever arising from the use of or reliance on 
            information, products, services, or related graphics contained on this website.
            <br></br>
            <br></br>
            By accessing and using Ezivote, you agree to comply with this disclaimer and accept 
           all associated risks. If you do not agree with any part of this disclaimer, 
           please discontinue your use of the website.

          </Text>
         
        </Box>
      </Flex>
    </>
  );
};

export default Policy;
