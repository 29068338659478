import { background } from '@chakra-ui/react';
import React from 'react';

const Points = () => {

  const containerStyle = { 
    maxWidth: '100%', 
    margin: '0 auto', 
    padding:"20px", 
    display:"flex", 
    flexDirection:"column",
    gap:"40px",
    alignItems:"center",
    backgroundColor: '#f4f4f4',
   };
  const tableStyle = {
    border: '1px solid black',
    width: '70%',
    // padding:"20px"
  };

  const cellStyle = {
    border: '1px solid black',
    textAlign: 'center', // Centering the cell data
    width: '200px', // Set fixed width for the table cells
  };

  const headerStyle = {
    ...cellStyle,
    backgroundColor: 'blue',
    color:"white",
    fontSize:"18px"
    
  };


  return (
    <div style={containerStyle}>
        <h1 style={{fontSize:"24px", fontWeight:"bold", color:"blue"}}>Point Distribution</h1>
      <table style={tableStyle}>
        <thead>
          <tr>
            <th colSpan="2" style={headerStyle}>Voting Points</th>
          </tr>
        </thead>
        <tbody>
          <tr style={cellStyle}>
            <td style={{cellStyle, fontWeight:"bold"}}>Votes</td>
            <td style={{cellStyle, fontWeight:"bold"}}>Points</td>
          </tr>
          <tr style={cellStyle}>
            <td style={cellStyle}>100</td>
            <td style={cellStyle}>1</td>
          </tr>
        </tbody>
      </table>
      <table style={tableStyle}>
        <thead>
          <tr>
            <th colSpan="2" style={headerStyle}>Milestone Points</th>
          </tr>
        </thead>
        <tbody>
          <tr style={cellStyle}>
            <td style={{cellStyle, fontWeight:"bold"}}>Votes</td>
            <td style={{cellStyle, fontWeight:"bold"}}>Points</td>
          </tr>
          <tr style={cellStyle}>
            <td style={cellStyle}>50k</td>
            <td style={cellStyle}>200</td>
          </tr>
          <tr style={cellStyle}>
            <td style={cellStyle}>100k</td>
            <td style={cellStyle}>400</td>
          </tr>
          <tr style={cellStyle}>
            <td style={cellStyle}>200k</td>
            <td style={cellStyle}>800</td>
          </tr>
          <tr style={cellStyle}>
            <td style={cellStyle}>300k</td>
            <td style={cellStyle}>1200</td>
          </tr>
          <tr style={cellStyle}>
            <td style={cellStyle}>400k</td>
            <td style={cellStyle}>1600</td>
          </tr>
          <tr style={cellStyle}>
            <td style={cellStyle}>500k</td>
            <td style={cellStyle}>5000</td>
          </tr>
        </tbody>
      </table>
      <table style={tableStyle}>
        <thead>
          <tr>
            <th colSpan="2" style={headerStyle}>Runner-up Points</th>
          </tr>
        </thead>
        <tbody>
          <tr style={cellStyle}>
            <td style={{cellStyle, fontWeight:"bold"}}>Votes upto</td>
            <td style={{cellStyle, fontWeight:"bold"}}>Points</td>
          </tr>
          <tr style={cellStyle}>
            <td style={cellStyle}>10k</td>
            <td style={cellStyle}>200</td>
          </tr>
          <tr style={cellStyle}>
            <td style={cellStyle}>20k</td>
            <td style={cellStyle}>400</td>
          </tr>
          <tr style={cellStyle}>
            <td style={cellStyle}>30k</td>
            <td style={cellStyle}>600</td>
          </tr>
          <tr style={cellStyle}>
            <td style={cellStyle}>40k</td>
            <td style={cellStyle}>800</td>
          </tr>
          <tr style={cellStyle}>
            <td style={cellStyle}>50k</td>
            <td style={cellStyle}>1200</td>
          </tr>
          <tr style={cellStyle}>
            <td style={cellStyle}>60k</td>
            <td style={cellStyle}>1400</td>
          </tr>
          <tr style={cellStyle}>
            <td style={cellStyle}>70k</td>
            <td style={cellStyle}>1600</td>
          </tr>
          <tr style={cellStyle}>
            <td style={cellStyle}>80k</td>
            <td style={cellStyle}>1800</td>
          </tr>
          <tr style={cellStyle}>
            <td style={cellStyle}>90k</td>
            <td style={cellStyle}>2000</td>
          </tr>
          <tr style={cellStyle}>
            <td style={cellStyle}>100k</td>
            <td style={cellStyle}>5000</td>
          </tr>
          <tr style={cellStyle}>
            <td style={cellStyle}>200k</td>
            <td style={cellStyle}>10000</td>
          </tr>
          <tr style={cellStyle}>
            <td style={cellStyle}>300k</td>
            <td style={cellStyle}>15000</td>
          </tr>
          <tr style={cellStyle}>
            <td style={cellStyle}>400k</td>
            <td style={cellStyle}>20000</td>
          </tr>
          <tr style={cellStyle}>
            <td style={cellStyle}>500k</td>
            <td style={cellStyle}>30000</td>
          </tr>
        </tbody>
      </table>
      <table style={tableStyle}>
        <thead>
          <tr>
            <th colSpan="2" style={headerStyle}>Victory Points</th>
          </tr>
        </thead>
        <tbody>
        <tr style={cellStyle}>
            <td style={{cellStyle, fontWeight:"bold"}}>Votes</td>
            <td style={{cellStyle, fontWeight:"bold"}}>Points</td>
          </tr>
          <tr style={cellStyle}>
            <td style={cellStyle}>50%</td>
            <td style={cellStyle}>5000</td>
          </tr>
          <tr style={cellStyle}>
            <td style={cellStyle}>60%</td>
            <td style={cellStyle}>7500</td>
          </tr>
          <tr style={cellStyle}>
            <td style={cellStyle}>70%</td>
            <td style={cellStyle}>10000</td>
          </tr>
          <tr style={cellStyle}>
            <td style={cellStyle}>80%</td>
            <td style={cellStyle}>12500</td>
          </tr>
          <tr style={cellStyle}>
            <td style={cellStyle}>90%</td>
            <td style={cellStyle}>15000</td>
          </tr>
        </tbody>
      </table>
      <table style={tableStyle}>
        <thead>
          <tr>
            <th colSpan="2" style={headerStyle}>Team Trumps</th>
          </tr>
        </thead>
        <tbody>
        <tr style={cellStyle}>
            <td style={{cellStyle, fontWeight:"bold"}}>Type</td>
            <td style={{cellStyle, fontWeight:"bold"}}>Points</td>
          </tr>
          <tr style={cellStyle}>
            <td style={cellStyle}>Leader of Ruling Party</td>
            <td style={cellStyle}>2x</td>
          </tr>
          <tr style={cellStyle}>
            <td style={cellStyle}>Leader of Opposition Party</td>
            <td style={cellStyle}>1.75x</td>
          </tr>
          <tr style={cellStyle}>
            <td style={cellStyle}>Home Minister</td>
            <td style={cellStyle}>1.5x</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Points;
