import axios from "axios";
import { REACT_API_URL } from "../utils/config";

export const getDailyContestList = async () => {
  try {
    const response = await axios.get(`${REACT_API_URL}/get-web-contest?contest_type=Daily`);
    return response.data.data;
  } catch (error) {
    throw new Error("Failed to fetch game list");
  }
};

export const getWeeklyContestList = async () => {
  try {
    const response = await axios.get(`${REACT_API_URL}/get-web-contest?contest_type=Weekly`);
    return response.data.data;
  } catch (error) {
    throw new Error("Failed to fetch game list");
  }
};

export const getMonthlyContestList = async () => {
  try {
    const response = await axios.get(`${REACT_API_URL}/get-web-contest?contest_type=Monthly`);
    return response.data.data;
  } catch (error) {
    throw new Error("Failed to fetch game list");
  }
};

export const GetTradingBannerAPI = async () => {
  try {
    const response = await axios.get(`${REACT_API_URL}/get-trading-banners`);
    return response.data.data;
  } catch (error) {
    throw new Error("Failed to fetch game list");
  }
};
