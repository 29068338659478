import React from "react";
// import Banner from "../../assets/banner.png";
import Banner from "../../assets/bannerImages/banner.webp";
// import BG from "../../assets/homeImage/BG.png";
import Icon1 from "../../assets/homeImage/Icon1.png";
import Icon2 from "../../assets/homeImage/Icon2.png";
// import Hex1 from "../../assets/homeImage/Polygon4.png";
import Hex2 from "../../assets/homeImage/polygon1.png";
// import Hex3 from "../../assets/homeImage/hex3.png";
import Egg1 from "../../assets/homeImage/pngegg1.png";
import Egg2 from "../../assets/homeImage/pngegg2.png";
// import Rev1 from "../../assets/homeImage/review1.png";
// import Rev2 from "../../assets/homeImage/review2.png";
// import Rev3 from "../../assets/homeImage/review3.png";
import BG5 from "../../assets/homeImage/BG5.png";
import Mobile from "../../assets/homeImage/mobile.png";
import Google from "../../assets/homeImage/google.png";
import Apple from "../../assets/homeImage/apple.png";
import QR from "../../assets/homeImage/qr.png";
import "./Home.css";
import Test from "./Testimonials";
// import T1 from "../../assets/homeImage/t1.png";
// import T2 from "../../assets/homeImage/t2.png";
// import T3 from "../../assets/homeImage/t3.png";
import { Text, Box, Image } from "@chakra-ui/react";
import RegionalPartnerPopup from "../Popup/regionalPartener";

const Home = () => {

  const handleGoogleClick = () => {
    window.open('https://play.google.com/store/apps/details?id=io.ionic.ezivote', '_blank');
  };
  // const handleAppleClick = () => {
  //   window.open('');
  // };
  return (
    <div>

      <RegionalPartnerPopup />
      <div>
        <img src={Banner} className="banner" alt="Banner" />
      </div>

      <Box
        width="100%"
        height="100%"
        position="relative"
        backgroundColor="#EFEFEF"
      >
        <Text
          fontFamily="Montserrat"
          fontSize="35px"
          fontWeight="500"
          textAlign="center"
          pt="20"
        >
          Our Political Fantasy Gaming Application
        </Text>
        <Text
          fontFamily="Montserrat"
          fontSize="20px"
          fontWeight="400"
          textAlign="justify"
          padding="3rem"
        >
          Welcome to Ezivote, a fantasy gaming platform combining the excitement
          of fantasy games with the dynamic sphere of politics! Our platform
          offers a distinctive fusion of political participation and strategic
          gameplay, redefining the gaming scene. Participate  in an innovative
          gaming experience that allows players to navigate through the twists
          and turns of a virtual political sphere, mirroring the complexities of
          real-world politics. Our game approach at Ezivote is a reflection of
          our belief in the strength of democratic values. In the game, players
          participate in a democratic process where they make choices, create
          alliances, and compete for leadership positions. It's an experience
          that promotes a greater comprehension of political nuances rather than
          merely a game.
          <br></br>
          <br></br>
          We want to inspire everyone to become more knowledgeable about Indian
          politics outside of the gaming world. Our platform acts as a doorway
          to political consciousness, providing players with knowledge and
          insights that enable them to make wise choices.The game eliminates the
          scope of favoritism, and the winner is the one who possesses the best
          skill set. Join us in this revolutionary gaming experience, where
          entertainment meets education, and let's embark on a journey to
          reshape the political landscape together.
        </Text>
        <Image
          src={Icon1}
          position="absolute"
          left="0px"
          top="0"
        />
        <Image
          src={Icon2}
          position="absolute"
          right="0px"
          bottom="0"
        />
      </Box>
      <div className="home-container3">
        <h1 className="home-container3-head">How to Play?</h1>
        <p className="home-container3-para">
          Our intuitive Interface makes it simpler to play Ezivote. Three
          different election categories are available in our political fantasy
          game: Central, State, and Zonal. Ezivote's round the clock support
          makes it easy to play, from registration to fast withdrawal.
        </p>
        <div className="ring-container">
          <div className="ring">
            <img src={Hex2} alt="hex2" />
            <p className="ring-head redh">STEP</p>
            <p className="ring-para">
              Register with the essential KYC credentials. You have the option
              to play absolutely free or add money using UPI and other payment
              methods.
            </p>
            <div className="strip red"></div>
          </div>
          <div className="ring">
            <img src={Hex2} alt="hex2" />
            <p className="ring-head yellowh">RULES</p>
            <p className="ring-para">
              Pick your election type (central, state, and zonal) and make your
              team accordingly. Ensure that you adhere to the rules and join the
              contest to make your dream cabinet.
            </p>
            <div className="strip yellow"></div>
          </div>
          <div className="ring">
            <img src={Hex2} alt="hex2" />
            <p className="ring-head greenh">POINT SYSTEMS</p>
            <p className="ring-para">
              Keep an eye on the rankings and point system. Then win using the
              right skill set, and you are good to go for the fast withdrawal.
            </p>
            <div className="strip green"></div>
          </div>
        </div>
      </div>


      <Box
        width="100%"
        height={{ base: "100%", md: "767px" }}
        position="relative"
        backgroundColor="#2E74C6"
      >
        <Image
          src={Egg1}
          position="absolute"
          left="0px"
          top="0px"
        />
        <Image
          src={Egg2}
          position="absolute"
          right="0px"
          bottom="0px"
        />
        <Text
          fontFamily="Montserrat"
          fontSize="35px"
          fontWeight="500"
          textAlign="center"
          pt="20"
          zIndex="2"
          color="white"
          marginBottom="2rem"
        >
          Reviews and Ratings
        </Text>

        <Test />
        {/* <Text
          fontFamily="Montserrat"
          fontSize="20px"
          fontWeight="400"
          textAlign="justify"
          padding="3rem"
        >
          Welcome to Ezivote, a fantasy gaming platform combining the excitement
          of fantasy games with the dynamic sphere of politics! Our platform
          offers a distinctive fusion of political participation and strategic
          gameplay, redefining the gaming scene. Participate  in an innovative
          gaming experience that allows players to navigate through the twists
          and turns of a virtual political sphere, mirroring the complexities of
          real-world politics. Our game approach at Ezivote is a reflection of
          our belief in the strength of democratic values. In the game, players
          participate in a democratic process where they make choices, create
          alliances, and compete for leadership positions. It's an experience
          that promotes a greater comprehension of political nuances rather than
          merely a game. 
          <br></br>
          <br></br>
          We want to inspire everyone to become more knowledgeable about Indian
          politics outside of the gaming world. Our platform acts as a doorway
          to political consciousness, providing players with knowledge and
          insights that enable them to make wise choices.The game eliminates the
          scope of favoritism, and the winner is the one who possesses the best
          skill set. Join us in this revolutionary gaming experience, where
          entertainment meets education, and let's embark on a journey to
          reshape the political landscape together.
        </Text> */}
      </Box>
      <div className="home-container5">
        <img src={BG5} alt="background" className="mobile-container" />
        <div className="item-container5">
          {/* <div> */}
          <h1 className="item-header5">GET THE APP</h1>
          {/* </div>  */}
          <div className="para-box">
            <p className="item-p-text">
              {" "}
              &#9733; Play with over 1.5 Lakhs users
            </p>
            {/* <br></br> */}
            <p className="item-p-text">
              {" "}
              &#9733; Invite your friends & collect big rewards
            </p>
            {/* <br></br> */}
            <p className="item-p-text">
              {" "}
              &#9733; Play more, earn coins & get exciting rewards
            </p>
          </div>
          <div className="item-img">
            <img src={Google} className="g-img" alt="google" style={{ cursor: "pointer" }} onClick={handleGoogleClick} />
            <img src={Apple} className="a-img" alt="applele" style={{ cursor: "pointer" }} 
            // onClick={handleAppleClick} 
            />
          </div>
          <div className="item-q-container">
            <img src={QR} className="q-img" alt="QR" />
            <p className="q-text">
              Scan the QR Code to download
              <br></br>
              the<span style={{ color: "#3379CB", cursor: "pointer" }} onClick={handleGoogleClick}> EziVote App</span>
            </p>
          </div>
        </div>
        <img src={Mobile} alt="mobile" className="mobile" />
      </div>
    </div>
  );
};

export default Home;
