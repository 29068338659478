import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

const Policy = () => {
  return (
    <>
      <Flex
        align="center"
        justify="center"
        width="100%"
        maxWidth="1366px"
        margin="0 auto"
        padding="20px 0"
        flexDir="column"
        backgroundColor="#f4f4f4"
      >
        {/*About Content  */}
        <Box
          flex="2"
          width={{ base: "100%" }}
          padding={{ base: "20px", md: "20px" }}
        >
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "20px", md: "20px", lg: "25px" }}
            fontWeight={{ base: 500, md: 500, lg: 500 }}
            lineHeight="1.5"
            // textAlign={{ base: "center", md: "justify" }}
            marginTop={{ base: "10px", md: "10px" }}
            marginBottom={{ base: "10px", md: "10px" }}
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
          >
            Privacy Policy for Ezivote
          </Text>
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "16px" }}
            fontWeight={400}
            lineHeight="24px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
            color="#696969"
          >
            Welcome to Ezivote, where political fantasy meets skill-based online
            gaming! Our commitment to ensuring your privacy and protecting your
            personal information is paramount. This comprehensive Privacy Policy
            is designed to provide transparency on how we collect, use, and
            safeguard your data while you immerse yourself in our captivating
            gaming experience.
          </Text>
        </Box>

        <Box
          flex="2"
          width={{ base: "100%" }}
          padding={{ base: "20px", md: "20px" }}
        >
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "20px", md: "20px", lg: "25px" }}
            fontWeight={{ base: 500, md: 500, lg: 500 }}
            lineHeight="1.5"
            // textAlign={{ base: "center", md: "justify" }}
            marginTop={{ base: "10px", md: "10px" }}
            marginBottom={{ base: "10px", md: "10px" }}
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
          >
            Legalities
          </Text>
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "16px" }}
            fontWeight={400}
            lineHeight="24px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
            color="#696969"
          >
            Creation of a politically neutral analytical gaming application with
            no room for manipulation. Obeying the Election Commission of India's
            instructions and all applicable laws pertaining to skill-based
            online gaming in India. Ensuring that all necessary certifications
            are periodically reviewed and updated. Providing people with
            round-the-clock support and emphasizing on feedback from customers
            received through multiple channels. Safeguarding data submitted by
            users on our platform.
          </Text>
        </Box>

        <Box
          flex="2"
          width={{ base: "100%" }}
          padding={{ base: "20px", md: "20px" }}
        >
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "20px", md: "20px", lg: "25px" }}
            fontWeight={{ base: 500, md: 500, lg: 500 }}
            lineHeight="1.5"
            // textAlign={{ base: "center", md: "justify" }}
            marginTop={{ base: "10px", md: "10px" }}
            marginBottom={{ base: "10px", md: "10px" }}
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
          >
            Purpose and Usage
          </Text>
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "16px" }}
            fontWeight={400}
            lineHeight="24px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
            color="#696969"
          >
            At Ezivote, our primary objective is to enhance your gaming
            experience. To achieve this, we collect and use your personal
            information for specific purposes, such as processing transactions,
            improving our services, and ensuring fair play within the game. Rest
            assured, we are dedicated to using your data responsibly and
            transparently.
          </Text>
        </Box>
        <Box
          flex="2"
          width={{ base: "100%" }}
          padding={{ base: "20px", md: "20px" }}
        >
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "20px", md: "20px", lg: "25px" }}
            fontWeight={{ base: 500, md: 500, lg: 500 }}
            lineHeight="1.5"
            // textAlign={{ base: "center", md: "justify" }}
            marginTop={{ base: "10px", md: "10px" }}
            marginBottom={{ base: "10px", md: "10px" }}
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
          >
            Disclosure
          </Text>
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "16px" }}
            fontWeight={400}
            lineHeight="24px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
            color="#696969"
          >
            We prioritize the confidentiality of your personal information.
            Unless required by law or with your explicit consent, we do not
            disclose your data to third parties. Your trust is essential to us,
            and we strive to maintain the highest level of security and
            discretion when handling your information.
          </Text>
        </Box>
        <Box
          flex="2"
          width={{ base: "100%" }}
          padding={{ base: "20px", md: "20px" }}
        >
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "20px", md: "20px", lg: "25px" }}
            fontWeight={{ base: 500, md: 500, lg: 500 }}
            lineHeight="1.5"
            // textAlign={{ base: "center", md: "justify" }}
            marginTop={{ base: "10px", md: "10px" }}
            marginBottom={{ base: "10px", md: "10px" }}
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
          >
            Use of Cookies
          </Text>
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "16px" }}
            fontWeight={400}
            lineHeight="24px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
            color="#696969"
          >
            To optimize your browsing experience, we employ cookies—small data
            files stored on your device. Cookies allow us to analyze user
            behavior, tailor content to your preferences, and provide a seamless
            gaming experience. You have the flexibility to manage your cookie
            preferences through your browser settings, giving you control over
            your online experience.
          </Text>
        </Box>
        <Box
          flex="2"
          width={{ base: "100%" }}
          padding={{ base: "20px", md: "20px" }}
        >
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "20px", md: "20px", lg: "25px" }}
            fontWeight={{ base: 500, md: 500, lg: 500 }}
            lineHeight="1.5"
            // textAlign={{ base: "center", md: "justify" }}
            marginTop={{ base: "10px", md: "10px" }}
            marginBottom={{ base: "10px", md: "10px" }}
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
          >
            Links
          </Text>
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "16px" }}
            fontWeight={400}
            lineHeight="24px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
            color="#696969"
          >
            While navigating through Ezivote, you may encounter links to
            external websites. It's important to note that we do not control the
            content or privacy practices of these third-party sites. We
            recommend reviewing their privacy policies before sharing any
            personal information on external platforms.
          </Text>
        </Box>
        <Box
          flex="2"
          width={{ base: "100%" }}
          padding={{ base: "20px", md: "20px" }}
        >
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "20px", md: "20px", lg: "25px" }}
            fontWeight={{ base: 500, md: 500, lg: 500 }}
            lineHeight="1.5"
            // textAlign={{ base: "center", md: "justify" }}
            marginTop={{ base: "10px", md: "10px" }}
            marginBottom={{ base: "10px", md: "10px" }}
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
          >
            Security Procedures
          </Text>
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "16px" }}
            fontWeight={400}
            lineHeight="24px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
            color="#696969"
          >
            Ensuring the security of your data is a top priority for us. We
            implement robust security measures to protect against unauthorized
            access, disclosure, alteration, and destruction of your information.
            Our systems undergo regular updates to maintain the highest
            standards of security and safeguard your gaming experience.
          </Text>
        </Box>
        <Box
          flex="2"
          width={{ base: "100%" }}
          padding={{ base: "20px", md: "20px" }}
        >
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "20px", md: "20px", lg: "25px" }}
            fontWeight={{ base: 500, md: 500, lg: 500 }}
            lineHeight="1.5"
            // textAlign={{ base: "center", md: "justify" }}
            marginTop={{ base: "10px", md: "10px" }}
            marginBottom={{ base: "10px", md: "10px" }}
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
          >
            Advertising
          </Text>
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "16px" }}
            fontWeight={400}
            lineHeight="24px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
            color="#696969"
          >
            To enhance your experience, we may display targeted advertisements
            based on your gaming preferences. These advertisements are intended
            to complement your gaming journey and are not shared with
            advertisers in a way that identifies you personally. We strive to
            strike a balance between delivering relevant content and respecting
            your privacy.
          </Text>
        </Box>
        <Box
          flex="2"
          width={{ base: "100%" }}
          padding={{ base: "20px", md: "20px" }}
        >
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "20px", md: "20px", lg: "25px" }}
            fontWeight={{ base: 500, md: 500, lg: 500 }}
            lineHeight="1.5"
            // textAlign={{ base: "center", md: "justify" }}
            marginTop={{ base: "10px", md: "10px" }}
            marginBottom={{ base: "10px", md: "10px" }}
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
          >
            Disclaimer
          </Text>
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "16px" }}
            fontWeight={400}
            lineHeight="24px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
            color="#696969"
          >
            Despite our stringent security measures, it's important to
            acknowledge that no online platform is entirely risk-free. By
            engaging with Ezivote, you acknowledge and accept the inherent
            security risks associated with online activities. We encourage you
            to remain vigilant and adopt best practices to safeguard your
            personal information.
          </Text>
        </Box>
        <Box
          flex="2"
          width={{ base: "100%" }}
          padding={{ base: "20px", md: "20px" }}
        >
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "20px", md: "20px", lg: "25px" }}
            fontWeight={{ base: 500, md: 500, lg: 500 }}
            lineHeight="1.5"
            // textAlign={{ base: "center", md: "justify" }}
            marginTop={{ base: "10px", md: "10px" }}
            marginBottom={{ base: "10px", md: "10px" }}
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
          >
            Conditions of Use
          </Text>
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "16px" }}
            fontWeight={400}
            lineHeight="24px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
            color="#696969"
          >
            Using Ezivote comes with an agreement to adhere to our Terms and
            Conditions. This includes fair play policies, respect for the rights
            of other users, and compliance with applicable laws. By using our
            platform, you actively participate in maintaining a positive and
            secure gaming environment.
          </Text>
        </Box>
        <Box
          flex="2"
          width={{ base: "100%" }}
          padding={{ base: "20px", md: "20px" }}
        >
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "20px", md: "20px", lg: "25px" }}
            fontWeight={{ base: 500, md: 500, lg: 500 }}
            lineHeight="1.5"
            // textAlign={{ base: "center", md: "justify" }}
            marginTop={{ base: "10px", md: "10px" }}
            marginBottom={{ base: "10px", md: "10px" }}
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
          >
            Retention of Data
          </Text>
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "16px" }}
            fontWeight={400}
            lineHeight="24px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
            color="#696969"
          >
            We retain your personal information for the duration necessary to
            fulfill the purposes outlined in this Privacy Policy. If you wish to
            have your data removed, you can do so by logging into your account
            or contacting our support team. Deletion requests will be processed
            by legal obligations.
          </Text>
        </Box>
        <Box
          flex="2"
          width={{ base: "100%" }}
          padding={{ base: "20px", md: "20px" }}
        >
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "20px", md: "20px", lg: "25px" }}
            fontWeight={{ base: 500, md: 500, lg: 500 }}
            lineHeight="1.5"
            // textAlign={{ base: "center", md: "justify" }}
            marginTop={{ base: "10px", md: "10px" }}
            marginBottom={{ base: "10px", md: "10px" }}
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
          >
            User Account and Deletion
          </Text>
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "16px" }}
            fontWeight={400}
            lineHeight="24px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
            color="#696969"
          >
            You have the right to access, correct, or delete your personal
            information. To exercise these rights, log in to your account or
            reach out to our support team. Deletion requests will be processed
            in compliance with legal requirements and our commitment to
            safeguarding your privacy.
          </Text>
        </Box>
        <Box
          flex="2"
          width={{ base: "100%" }}
          padding={{ base: "20px", md: "20px" }}
        >
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "20px", md: "20px", lg: "25px" }}
            fontWeight={{ base: 500, md: 500, lg: 500 }}
            lineHeight="1.5"
            // textAlign={{ base: "center", md: "justify" }}
            marginTop={{ base: "10px", md: "10px" }}
            marginBottom={{ base: "10px", md: "10px" }}
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
          >
            Applicable Laws and Jurisdiction
          </Text>
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "16px" }}
            fontWeight={400}
            lineHeight="24px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
            color="#696969"
          >
            This Privacy Policy is governed by and construed by the laws of the
            Indian States. Any disputes arising from your use of Ezivote fall
            under the exclusive jurisdiction of the courts in the Indian States.
            This commitment to a specific legal framework provides clarity and
            reinforces our dedication to protecting your rights.
          </Text>
        </Box>
        <Box
          flex="2"
          width={{ base: "100%" }}
          padding={{ base: "20px", md: "20px" }}
        >
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "20px", md: "20px", lg: "25px" }}
            fontWeight={{ base: 500, md: 500, lg: 500 }}
            lineHeight="1.5"
            // textAlign={{ base: "center", md: "justify" }}
            marginTop={{ base: "10px", md: "10px" }}
            marginBottom={{ base: "10px", md: "10px" }}
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
          >
            Information Update
          </Text>
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "16px" }}
            fontWeight={400}
            lineHeight="24px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
            color="#696969"
          >
            We understand that privacy concerns may evolve. Therefore, we may
            update this Privacy Policy periodically to reflect changes in our
            practices or legal requirements. Stay informed by reviewing the
            latest version, which will be posted on our website.
          </Text>
        </Box>
        <Box
          flex="2"
          width={{ base: "100%" }}
          padding={{ base: "20px", md: "20px" }}
        >
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "20px", md: "20px", lg: "25px" }}
            fontWeight={{ base: 500, md: 500, lg: 500 }}
            lineHeight="1.5"
            // textAlign={{ base: "center", md: "justify" }}
            marginTop={{ base: "10px", md: "10px" }}
            marginBottom={{ base: "10px", md: "10px" }}
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
          >
            Contact Us
          </Text>
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "16px" }}
            fontWeight={400}
            lineHeight="24px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
            color="#696969"
          >
          Your feedback, questions, or concerns regarding our Privacy Policy are important to us. Feel free to reach out to us call +91 98701 65276 and email at contact@ezivote.in. We appreciate your choice of Ezivote, and we remain committed to providing you with a thrilling gaming experience while prioritizing your privacy and security.
          </Text>
        </Box>
      </Flex>
    </>
  );
};

export default Policy;
