import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
// import Banner from "../../assets/banner.png";
import Banner from "../../assets/bannerImages/about.webp";

const About = () => {
  return (
    <Box>
      {/* Banner */}
      {/* <Box
        width="100%"
        height={{ base: "145px", md: "514px" }}
        backgroundImage={`url(${Banner})`}
        backgroundSize="cover"
        backgroundPosition="center"
      /> */}
      <img
        src={Banner}
        style={{
          widht: "100%",
          display: "block",
          height: "auto",
          padding: "0",
          margin: "0",
        }}
      />

      {/* About Section */}
      <Flex
        align="center"
        justify="center"
        width="100%"
        maxWidth="1366px"
        margin="0 auto"
        padding="20px 0"
      >
        {/*About Content  */}
        <Box
          flex="2"
          width={{ base: "100%" }}
          padding={{ base: "20px", md: "20px" }}
        >
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "20px", md: "20px", lg: "35px" }}
            fontWeight={{ base: 500, md: 500, lg: 500 }}
            lineHeight="1.5"
            // textAlign={{ base: "center", md: "justify" }}
            marginTop={{ base: "20px", md: "30px" }}
            marginBottom={{ base: "20px", md: "30px" }}
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
          >
            About us
          </Text>
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "20px" }}
            fontWeight={400}
            lineHeight="24px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
            color="#696969"
          >
            {/* The future of mobile gaming lies in the hands of skill-based games
            where players win using creative and analytical skills. We at
            Ezivote began considering this trend, and we are now a growing team
            of motivated individuals with technical expertise. The game
            developed by us comes without any aspect of favoritism, and winning
            strictly depends on the skill set of the player. We engage, inform,
            and update players hailing from diverse groups, which makes them
            aware citizens and also adds to their political knowledge. We have a
            highly secure, user-friendly interface for players who want to
            witness a different gaming experience. Fast and safe withdrawal is
            also among our primary considerations, and we also look for every
            scope of development. At Ezivote, our core values include
            transparency, morality, cooperation, and dedication. We consider our
            teammates to be our most valuable asset and believe in mutual
            progress. While participating, you can have the guarantee of a
            unique experience with all the standard regulations followed and
            queries addressed in no time. */}
            The future of mobile gaming lies in skill-based games where players
            win using creative and analytical skills. At Ezivote, we recognize
            this trend and have built a growing team of motivated individuals
            with technical expertise. Our game ensures fairness, with victories
            determined solely by player skill. We engage, inform, and update
            players from diverse backgrounds, enhancing their awareness and
            political knowledge. Our highly secure, user-friendly interface
            offers a unique gaming experience, prioritizing fast and safe
            withdrawals.
            <br />
            <br />
            At Ezivote, our core values include transparency, morality,
            cooperation, and dedication. We believe in mutual progress,
            considering our teammates as our most valuable asset. Participants
            can expect a unique experience, with all standard regulations
            followed and queries addressed promptly.
            <br />
            <br />
            Ezivote Knowledge Trading
            <br />
            Our 'Daily, Weekly, and monthly Contest' offers a fantastic
            opportunity to monetize your skills. Share your opinion with a
            simple Yes or No for a chance to win real money. Explore Ezivote's
            opinion trading section, featuring questions on daily happenings
            from politics, sports, entertainment, and more.
            <br/>
            <br/>
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default About;
