import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

const Terms = () => {
  return (
    <>
      <Flex
        align="center"
        justify="center"
        width="100%"
        maxWidth="1366px"
        margin="0 auto"
        padding="20px 0"
        flexDir="column"
        backgroundColor="#f4f4f4"
      >
        {/*About Content  */}
        <Box
          flex="2"
          width={{ base: "100%" }}
          padding={{ base: "20px", md: "20px" }}
        >
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "20px", md: "20px", lg: "25px" }}
            fontWeight={{ base: 500, md: 500, lg: 500 }}
            lineHeight="1.5"
            // textAlign={{ base: "center", md: "justify" }}
            marginTop={{ base: "10px", md: "10px" }}
            marginBottom={{ base: "10px", md: "10px" }}
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
          >
            Terms and Conditions for Ezivote
          </Text>
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "16px" }}
            fontWeight={400}
            lineHeight="24px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
            color="#696969"
          >
            Welcome to Ezivote, India's leading skill-based real-money political
            fantasy gaming application. By engaging with our services, you
            affirm your acceptance and adherence to the following comprehensive
            terms and conditions. Kindly read through these terms attentively
            before utilizing our application.
          </Text>
        </Box>
        <Box
          flex="2"
          width={{ base: "100%" }}
          padding={{ base: "20px", md: "20px" }}
        >
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "20px", md: "20px", lg: "25px" }}
            fontWeight={{ base: 500, md: 500, lg: 500 }}
            lineHeight="1.5"
            // textAlign={{ base: "center", md: "justify" }}
            marginTop={{ base: "10px", md: "10px" }}
            marginBottom={{ base: "10px", md: "10px" }}
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
          >
            1. Acceptance of Terms
          </Text>
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "16px" }}
            fontWeight={400}
            lineHeight="24px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
            color="#696969"
          >
            By accessing Ezivote, you signify your understanding and agreement
            with these terms and conditions. If any part of these terms is not
            acceptable to you, refrain from using our services.
          </Text>
        </Box>
        <Box
          flex="2"
          width={{ base: "100%" }}
          padding={{ base: "20px", md: "20px" }}
        >
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "20px", md: "20px", lg: "25px" }}
            fontWeight={{ base: 500, md: 500, lg: 500 }}
            lineHeight="1.5"
            // textAlign={{ base: "center", md: "justify" }}
            marginTop={{ base: "10px", md: "10px" }}
            marginBottom={{ base: "10px", md: "10px" }}
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
          >
            2. Eligibility
          </Text>
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "16px" }}
            fontWeight={400}
            lineHeight="24px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
            color="#696969"
          >
            To use Ezivote you must be at least 18 years old and legally
            eligible for real-money gaming in India. By utilizing our services,
            you confirm your compliance with these age and eligibility
            requirements.
          </Text>
        </Box>
        <Box
          flex="2"
          width={{ base: "100%" }}
          padding={{ base: "20px", md: "20px" }}
        >
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "20px", md: "20px", lg: "25px" }}
            fontWeight={{ base: 500, md: 500, lg: 500 }}
            lineHeight="1.5"
            // textAlign={{ base: "center", md: "justify" }}
            marginTop={{ base: "10px", md: "10px" }}
            marginBottom={{ base: "10px", md: "10px" }}
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
          >
            3. Account Registration
          </Text>
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "16px" }}
            fontWeight={400}
            lineHeight="24px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
            color="#696969"
          >
            To access our application, user registration may be required. During
            the registration process, you commit to providing accurate and
            complete information. Safeguard the confidentiality of your account
            details and assume responsibility for all activities occurring under
            your account.
          </Text>
        </Box>
        <Box
          flex="2"
          width={{ base: "100%" }}
          padding={{ base: "20px", md: "20px" }}
        >
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "20px", md: "20px", lg: "25px" }}
            fontWeight={{ base: 500, md: 500, lg: 500 }}
            lineHeight="1.5"
            // textAlign={{ base: "center", md: "justify" }}
            marginTop={{ base: "10px", md: "10px" }}
            marginBottom={{ base: "10px", md: "10px" }}
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
          >
            4. Skill-Based Gaming
          </Text>
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "16px" }}
            fontWeight={400}
            lineHeight="24px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
            color="#696969"
          >
            Ezivote is dedicated to providing a platform for skill-based gaming,
            where user expertise influences the game outcome. The application
            ensures that results are determined by players' skills and knowledge
            rather than chance.
          </Text>
        </Box>
        <Box
          flex="2"
          width={{ base: "100%" }}
          padding={{ base: "20px", md: "20px" }}
        >
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "20px", md: "20px", lg: "25px" }}
            fontWeight={{ base: 500, md: 500, lg: 500 }}
            lineHeight="1.5"
            // textAlign={{ base: "center", md: "justify" }}
            marginTop={{ base: "10px", md: "10px" }}
            marginBottom={{ base: "10px", md: "10px" }}
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
          >
            5. Real Money Gaming
          </Text>
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "16px" }}
            fontWeight={400}
            lineHeight="24px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
            color="#696969"
          >
            Participation in real-money contests on Ezivote is subject to users
            utilizing their funds and expressing a genuine interest in such
            contests. By entering real-money contests, users acknowledge and
            agree to these conditions.
          </Text>
        </Box>
        <Box
          flex="2"
          width={{ base: "100%" }}
          padding={{ base: "20px", md: "20px" }}
        >
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "20px", md: "20px", lg: "25px" }}
            fontWeight={{ base: 500, md: 500, lg: 500 }}
            lineHeight="1.5"
            // textAlign={{ base: "center", md: "justify" }}
            marginTop={{ base: "10px", md: "10px" }}
            marginBottom={{ base: "10px", md: "10px" }}
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
          >
            6. Payment and Transactions
          </Text>
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "16px" }}
            fontWeight={400}
            lineHeight="24px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
            color="#696969"
          >
            Secure payment gateways facilitate payments for contest entries and
            winnings. Users agree to comply with payment terms and conditions
            presented by the application's designated payment service providers.
          </Text>
        </Box>
        <Box
          flex="2"
          width={{ base: "100%" }}
          padding={{ base: "20px", md: "20px" }}
        >
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "20px", md: "20px", lg: "25px" }}
            fontWeight={{ base: 500, md: 500, lg: 500 }}
            lineHeight="1.5"
            // textAlign={{ base: "center", md: "justify" }}
            marginTop={{ base: "10px", md: "10px" }}
            marginBottom={{ base: "10px", md: "10px" }}
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
          >
            7. Responsible Gaming
          </Text>
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "16px" }}
            fontWeight={400}
            lineHeight="24px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
            color="#696969"
          >
            Promoting responsible gaming, Ezivote encourages users to seek
            assistance from relevant support organizations if gaming activities
            become problematic. The application reserves the right to impose
            account limits or restrict access to services in the case of
            identified irresponsible gaming behavior.
          </Text>
        </Box>
        <Box
          flex="2"
          width={{ base: "100%" }}
          padding={{ base: "20px", md: "20px" }}
        >
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "20px", md: "20px", lg: "25px" }}
            fontWeight={{ base: 500, md: 500, lg: 500 }}
            lineHeight="1.5"
            // textAlign={{ base: "center", md: "justify" }}
            marginTop={{ base: "10px", md: "10px" }}
            marginBottom={{ base: "10px", md: "10px" }}
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
          >
            8. Fair Play
          </Text>
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "16px" }}
            fontWeight={400}
            lineHeight="24px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
            color="#696969"
          >
            Maintaining a fair and equitable gaming environment is a priority
            Ezivote strictly prohibits cheating, collusion, or fraudulent
            activities. Engaging in such activities will result in immediate
            account suspension and potential reporting to relevant authorities.
          </Text>
        </Box>
        <Box
          flex="2"
          width={{ base: "100%" }}
          padding={{ base: "20px", md: "20px" }}
        >
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "20px", md: "20px", lg: "25px" }}
            fontWeight={{ base: 500, md: 500, lg: 500 }}
            lineHeight="1.5"
            // textAlign={{ base: "center", md: "justify" }}
            marginTop={{ base: "10px", md: "10px" }}
            marginBottom={{ base: "10px", md: "10px" }}
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
          >
            9. Intellectual Property
          </Text>
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "16px" }}
            fontWeight={400}
            lineHeight="24px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
            color="#696969"
          >
            All content and materials on Ezivote are the exclusive intellectual
            property of the application. Unauthorized reproduction,
            distribution, or modification of any content is strictly prohibited
            without explicit permission.
          </Text>
        </Box>
        <Box
          flex="2"
          width={{ base: "100%" }}
          padding={{ base: "20px", md: "20px" }}
        >
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "20px", md: "20px", lg: "25px" }}
            fontWeight={{ base: 500, md: 500, lg: 500 }}
            lineHeight="1.5"
            // textAlign={{ base: "center", md: "justify" }}
            marginTop={{ base: "10px", md: "10px" }}
            marginBottom={{ base: "10px", md: "10px" }}
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
          >
            10. Termination
          </Text>
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "16px" }}
            fontWeight={400}
            lineHeight="24px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
            color="#696969"
          >
            Ezivote reserves the right to terminate or suspend user accounts
            without prior notice for any violation of these terms and
            conditions.
          </Text>
        </Box>
        <Box
          flex="2"
          width={{ base: "100%" }}
          padding={{ base: "20px", md: "20px" }}
        >
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "20px", md: "20px", lg: "25px" }}
            fontWeight={{ base: 500, md: 500, lg: 500 }}
            lineHeight="1.5"
            // textAlign={{ base: "center", md: "justify" }}
            marginTop={{ base: "10px", md: "10px" }}
            marginBottom={{ base: "10px", md: "10px" }}
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
          >
            11. Updates to Terms and Conditions
          </Text>
          <Text
            fontFamily="Montserrat"
            fontSize={{ base: "16px" }}
            fontWeight={400}
            lineHeight="24px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "60px" }}
            paddingRight={{ base: "20px", md: "60px" }}
            color="#696969"
          >
            Periodic updates to these terms and conditions may occur. Users are
            responsible for regularly reviewing these terms. Continued use of
            Ezivote after any changes constitutes acceptance of the updated
            terms.
          </Text>
        </Box>
      </Flex>
    </>
  );
};

export default Terms;
