import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@chakra-ui/react';
import axios from 'axios';
import Card from './Card';

function Blog() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.ezivote.com/api/v1/user/political-insights-list');
        setData(response.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Box width="100%" display="flex" alignItem="center" justifyContent="center" backgroundColor="#f4f4f4">
    <Grid
      templateColumns={{ base: '1fr', sm:'repeat(2, 1fr)', md: 'repeat(3, 1fr)'}}
      gap={8} 
      paddingBottom="50px"
      paddingTop="50px"
      
      justifyContent="center" 
      width="90%"
    >
      {data.map(item => (
        <Card key={item.id} data={item} />
      ))}
    </Grid>
    </Box>
  );
}

export default Blog;








































































// import React, { useState, useEffect } from 'react';
// import { Box, Grid, Image, Text, Button } from '@chakra-ui/react';

// const Card = ({ data, handleClick }) => {
//   return (
//     <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p="4">
//       <Image src={data.image} alt={data.title} />
//       <Box p="6">
//         <Text fontWeight="bold" fontSize="lg" mb="2">
//           {data.title}
//         </Text>
//         <Text>{data.description.slice(0, 20)}...</Text>
//         <Button mt="4" onClick={() => handleClick(data.id)}>Read More</Button>
//       </Box>
//     </Box>
//   );
// };

// const DetailsCard = ({ details }) => {
//   return (
//     <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p="4">
//       <Image src={details.image} alt={details.title} />
//       <Box p="6">
//         <Text fontWeight="bold" fontSize="lg" mb="2">
//           {details.title}
//         </Text>
//         <Text>{details.description}</Text>
//       </Box>
//     </Box>
//   );
// };

// const MainComponent = () => {
//   const [data, setData] = useState([]);
//   const [selectedId, setSelectedId] = useState(null);
//   const [details, setDetails] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch('https://api.ezivote.com/api/v1/user/political-insights-list');
//         const result = await response.json();
//         setData(result.data);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   const handleClick = async (id) => {
//     setSelectedId(id);
//     try {
//       const response = await fetch(`https://api.ezivote.com/api/v1/user/political-insights-detail/${id}`);
//       const result = await response.json();
//       setDetails(result.data);
//     } catch (error) {
//       console.error('Error fetching details:', error);
//     }
//   };

//   return (
//     <Box p="4">
//       <Grid templateColumns="repeat(3, 1fr)" gap={6}>
//         {data.map(item => (
//           <Card key={item.id} data={item} handleClick={handleClick} />
//         ))}
//       </Grid>
//       {selectedId && details && <DetailsCard details={details} />}
//     </Box>
//   );
// };

// export default MainComponent;
