import { Box, Text, Image, VStack, HStack, Flex, Heading } from "@chakra-ui/react";
import T1 from "../../assets/homeImage/t1.png";
import T2 from "../../assets/homeImage/t2.png";
import T3 from "../../assets/homeImage/t3.png";
import Star from "../../assets/homeImage/star.png";

const Testimonial = () => {
  return (
    <>
    <Flex 
    flexDirection={{base:"column", lg:"row"}} 
    gap={{base:"20",md:"10"}} 
    // marginLeft={{base:"-1.8", md:"4%"}}
    paddingBottom={{base:"2rem", md:"4rem"}}
    width="100%"
    alignItems="center"
    justifyContent="center"
    >
      <Box marginTop={{base:"2rem", md:"9.5rem"}}>
        <VStack
          width={{ base: "80%", md: "381px" }}
          height={{base:"18rem",md:"23rem"}}
          backgroundColor="white"
          textAlign="center"
          position="relative"
          margin="auto"
          alignItems="center"
          borderRadius="10px"
        >
          <Flex
            position="relative"
            zIndex="2"
            flexDirection="column"
            paddingTop={{base:"2.1rem",md:"6rem"}}
            alignItems="center"
          >
            <Image
              src={T1}
              alt="Profile Picture"
              position="absolute"
              left="50%"
              transform="translateX(-50%)"
              zIndex="1"
              borderRadius="50%"
              boxShadow="lg"
              objectFit="cover"
              top={{base:"-15%", md:"-22%"}}
              width={{base:"22%",md:"35%"}}
            />
            <HStack alignSelf="center" paddingTop={{base:"0.9rem", md:"1rem"}}>
              <Image src={Star} alt="star" width={{base:"17%", md:"100%"}}/>
              <Image src={Star} alt="star" width={{base:"17%", md:"100%"}}/>
              <Image src={Star} alt="star" width={{base:"17%", md:"100%"}}/>
              <Image src={Star} alt="star" width={{base:"17%", md:"100%"}}/>
              <Image src={Star} alt="star" width={{base:"17%", md:"100%"}}/>
            </HStack>
            <Text
              fontFamily="Montserrat"
              fontSize={{ base: "0.8rem", md: "1.5rem" }}
              fontWeight="500"
              textAlign="center"
              mt="10px"
              mb="10px"
              px={{ base: "10px", md: "20px" }}
            >
              Subhash Bisht
            </Text>
            <Text
              fontFamily="Montserrat"
              fontSize={{ base: "14px", md: "16px" }}
              fontWeight="400"
              lineHeight="23px"
              letterSpacing="0em"
              textAlign="justified"
              px={{ base: "10px", md: "20px" }}
            >
              My keen interest in politics made me consider this application. It
              has a user-friendly interface and also comes with various types of
              elections to choose from. Additionally, it offers consistent
              updates on global political developments.
            </Text>
          </Flex>
        </VStack>
      </Box>
      <Box marginTop={{base:"2rem", md:"5rem"}}>
        <VStack
          width={{ base: "80%", md: "381px" }}
          height={{base:"17rem",md:"23rem"}}
          backgroundColor="white"
          textAlign="center"
          position="relative"
          //   overflow="hidden"
          margin="auto"
          alignItems="center"
          borderRadius="10px"
        >
          <Flex
            position="relative"
            zIndex="2"
            flexDirection="column"
            paddingTop={{base:"2.1rem",md:"6rem"}}
            alignItems="center"
          >
            <Image
              src={T2}
              alt="Profile Picture"
              position="absolute"
              top={{base:"-15%", md:"-22%"}}
              width={{base:"22%",md:"35%"}}
              left="50%"
              transform="translateX(-50%)"
              zIndex="1"
              borderRadius="50%"
              boxShadow="lg"
              objectFit="cover"
             
            />
            <HStack alignSelf="center" paddingTop={{base:"0.9rem", md:"1rem"}}>
              <Image src={Star} alt="star" width={{base:"17%", md:"100%"}}/>
              <Image src={Star} alt="star" width={{base:"17%", md:"100%"}}/>
              <Image src={Star} alt="star" width={{base:"17%", md:"100%"}}/>
              <Image src={Star} alt="star" width={{base:"17%", md:"100%"}}/>
              <Image src={Star} alt="star" width={{base:"17%", md:"100%"}}/>
            </HStack>
            <Text
              fontFamily="Montserrat"
              fontSize={{ base: "18px", md: "24px" }}
              fontWeight="500"
              textAlign="center"
              mt="10px"
              mb="10px"
              px={{ base: "10px", md: "20px" }}
            >
              Sneha Kapoor
            </Text>
            <Text
              fontFamily="Montserrat"
              fontSize={{ base: "14px", md: "16px" }}
              fontWeight="400"
              lineHeight="23px"
              letterSpacing="0em"
              textAlign="justified"
              px={{ base: "10px", md: "20px" }}
            >
              Political fantasy gaming is something that excites every Indian
              political enthusiast. With Ezivote, it's easier for me to know
              about elections across the globe, and I too have a chance to form
              my dream cabinet.
            </Text>
          </Flex>
        </VStack>
      </Box>
      <Box marginTop={{base:"2rem", md:"9.5rem"}}>
        <VStack
         width={{ base: "80%", md: "381px" }}
         height={{base:"17rem",md:"23rem"}}
          backgroundColor="white"
          textAlign="center"
          position="relative"
          //   overflow="hidden"
          margin="auto"
          alignItems="center"
          borderRadius="10px"
        >
          <Flex
            position="relative"
            zIndex="2"
            flexDirection="column"
            paddingTop={{base:"2.1rem",md:"6rem"}}
            alignItems="center"
          >
            <Image
              src={T3}
              alt="Profile Picture"
              position="absolute"
              left="50%"
              transform="translateX(-50%)"
              zIndex="1"
              borderRadius="50%"
              boxShadow="lg"
              objectFit="cover"
              top={{base:"-15%", md:"-22%"}}
              width={{base:"22%",md:"35%"}}
            />
            <HStack alignSelf="center" paddingTop={{base:"0.9rem", md:"1rem"}}>
              <Image src={Star} alt="star" width={{base:"17%", md:"100%"}}/>
              <Image src={Star} alt="star" width={{base:"17%", md:"100%"}}/>
              <Image src={Star} alt="star" width={{base:"17%", md:"100%"}}/>
              <Image src={Star} alt="star" width={{base:"17%", md:"100%"}}/>
              <Image src={Star} alt="star" width={{base:"17%", md:"100%"}}/>
            </HStack>
            <Text
              fontFamily="Montserrat"
              fontSize={{ base: "0.8rem", md: "1.5rem" }}
              fontWeight="500"
              textAlign="center"
              mt="10px"
              mb="10px"
              px={{ base: "10px", md: "20px" }}
            >
              Ramesh Kumar
            </Text>
            <Text
              fontFamily="Montserrat"
              fontSize={{ base: "14px", md: "16px" }}
              fontWeight="400"
              lineHeight="23px"
              letterSpacing="0em"
              textAlign="justified"
              px={{ base: "10px", md: "20px" }}

            >
              This application is a game changer in the world of skill-based
              games because it covers political updates and has a variety of
              elections to choose from. Also, one can pick from a wide array of
              election types and leader categories.
            </Text>
          </Flex>
        </VStack>
      </Box> 
    </Flex>

    </>
  );
};

export default Testimonial;
