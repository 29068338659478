import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import imgLeft from '../../../assets/electionImage/left-img.png'
import imgRight from '../../../assets/electionImage/right-img.png'
import imgCenter from '../../../assets/electionImage/center-img.png'
import { Box, Text, Image, Divider } from "@chakra-ui/react";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

import './Cards.css';

// import required modules
import { Navigation } from 'swiper/modules';

export default function App() {
 
   const isMobile = window.matchMedia('(max-width: 992px)').matches;

  return (
    <>
      <Swiper 
        navigation={true}
      slidesPerView={isMobile ? 1 : 2} 
      modules={[Navigation]} 
      className="mySwiper">
        <SwiperSlide>
        <Box
      // width="508px"
      width={{base:"80%", md:"508px"}}
      borderRadius="10px"
      margin="0 auto"
      position="relative"
      border="1px solid #333333"
      height={{base:"170px", md:"216px"}}
      background="white"
    >
      <Image
        src={imgLeft}
        alt="Left Image"
        style={{ position: "absolute", top: 0, left: 0 }}
        width={{base:"70%", md:"65%"}}
      />
      <Image
        src={imgRight}
        alt="Right Image"
        style={{ position: "absolute", top: 0, right: 0 }}
        width={{base:"50%", md:"45%"}}
      />
      <Image
        src={imgCenter}
        alt="Center Image"
        style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
        width={{base:"33%", md:"29%"}}
      />
      <Text
        position="absolute"
        top="10px"
        left="10px"
        // fontWeight="bold"
        fontSize={{base:"0.8rem", md:"1rem"}}
        fontWeight="500"
      >
        Chhatisgarh | INDIA
      </Text>
      <Text
        position="absolute"
        top="5px"
        right="8px"
        fontSize={{base:"0.8rem", md:"1rem"}}
        fontWeight="500"
        color="#09A347"
        // textAlign="right"
      >
        Nominees
        <br></br>
         <Text 
          fontSize={{base:"0.6rem", md:"0.8rem"}}
          fontWeight="500"
          color="black"
         >
        (Nomination closed)
         </Text>
      </Text>
      <Text
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        textAlign="center"
        fontSize={{base:"1rem", md:"1.2rem"}}
        fontWeight="600"
        color="#1C4779"
      >
        Vidhan Sabha 2023 
        <Text
         fontSize={{base:"0.7rem", md:"0.9rem"}}
         fontWeight="500"
         color="red"
        >
        Nov <Text color="black" display="inline">2023</Text>
      </Text>
          </Text>
      <Text
        position="absolute"
        bottom="10px"
        left="10px"
        fontWeight="600"
        fontSize={{base:"0.8rem", md:"1rem"}}
        color="black"
      >
        16th Election
      </Text>
      <Text
        position="absolute"
        bottom="10px"
        left="50%"
        transform="translateX(-50%)"
        fontWeight="600"
        fontSize={{base:"0.8rem", md:"1rem"}}
        color="#4D8AD1"
      >
        MEGA 28 Lakhs
      </Text>
      <Text
        position="absolute"
        bottom="10px"
        right="10px"
        fontWeight="600"
        fontSize={{base:"0.8rem", md:"1rem"}}
        color="#BA3F3F"
      >
        200 Seats
      </Text>
      <Divider 
      orientation="horizontal"
      margin="auto" 
      width="100%"
      position="absolute"
      bottom="21.5%"
      backgroundColor="#E0E0E0"
      />
    </Box>
        </SwiperSlide>
       <SwiperSlide>
        <Box
      // width="508px"
      width={{base:"80%", md:"508px"}}
      borderRadius="10px"
      margin="0 auto"
      position="relative"
      border="1px solid #333333"
      height={{base:"170px", md:"216px"}}
      background="white"
    >
   <Image
        src={imgLeft}
        alt="Left Image"
        style={{ position: "absolute", top: 0, left: 0 }}
        width={{base:"70%", md:"65%"}}
      />
      <Image
        src={imgRight}
        alt="Right Image"
        style={{ position: "absolute", top: 0, right: 0 }}
        width={{base:"50%", md:"45%"}}
      />
      <Image
        src={imgCenter}
        alt="Center Image"
        style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
        width={{base:"33%", md:"29%"}}
      />
      <Text
        position="absolute"
        top="10px"
        left="10px"
        // fontWeight="bold"
        fontSize={{base:"0.8rem", md:"1rem"}}
        fontWeight="500"
      >
        Madhya Pradesh | INDIA
      </Text>
      <Text
        position="absolute"
        top="5px"
        right="8px"
        fontSize={{base:"0.8rem", md:"1rem"}}
        fontWeight="500"
        color="#09A347"
        // textAlign="right"
      >
        Nominees
        <br></br>
         <Text 
          fontSize={{base:"0.6rem", md:"0.8rem"}}
          fontWeight="500"
          color="black"
         >
        (Nomination closed)
         </Text>
      </Text>
      <Text
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        textAlign="center"
        fontSize={{base:"1rem", md:"1.2rem"}}
        fontWeight="600"
        color="#1C4779"
      >
        Vidhan Sabha 2023 
        <Text
         fontSize={{base:"0.7rem", md:"0.9rem"}}
         fontWeight="500"
         color="red"
        >
        Nov <Text color="black" display="inline">2023</Text>
      </Text>
          </Text>
      <Text
        position="absolute"
        bottom="10px"
        left="10px"
        fontWeight="600"
        fontSize={{base:"0.8rem", md:"1rem"}}
        color="black"
      >
        16th Election
      </Text>
      <Text
        position="absolute"
        bottom="10px"
        left="50%"
        transform="translateX(-50%)"
        fontWeight="600"
        fontSize={{base:"0.8rem", md:"1rem"}}
        color="#4D8AD1"
      >
        MEGA 28 Lakhs
      </Text>
      <Text
        position="absolute"
        bottom="10px"
        right="10px"
        fontWeight="600"
        fontSize={{base:"0.8rem", md:"1rem"}}
        color="#BA3F3F"
      >
        200 Seats
      </Text>
      <Divider 
      orientation="horizontal"
      margin="auto" 
      width="100%"
      position="absolute"
      bottom="21.5%"
      backgroundColor="#E0E0E0"
      />
    </Box>
        </SwiperSlide>
        <SwiperSlide>
        <Box
      // width="508px"
      width={{base:"80%", md:"508px"}}
      borderRadius="10px"
      margin="0 auto"
      position="relative"
      border="1px solid #333333"
      height={{base:"170px", md:"216px"}}
      background="white"
    >
     <Image
        src={imgLeft}
        alt="Left Image"
        style={{ position: "absolute", top: 0, left: 0 }}
        width={{base:"70%", md:"65%"}}
      />
      <Image
        src={imgRight}
        alt="Right Image"
        style={{ position: "absolute", top: 0, right: 0 }}
        width={{base:"50%", md:"45%"}}
      />
      <Image
        src={imgCenter}
        alt="Center Image"
        style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
        width={{base:"33%", md:"29%"}}
      />
      <Text
        position="absolute"
        top="10px"
        left="10px"
        // fontWeight="bold"
        fontSize={{base:"0.8rem", md:"1rem"}}
        fontWeight="500"
      >
        Mizoram | INDIA
      </Text>
      <Text
        position="absolute"
        top="5px"
        right="8px"
        fontSize={{base:"0.8rem", md:"1rem"}}
        fontWeight="500"
        color="#09A347"
        // textAlign="right"
      >
        Nominees
        <br></br>
         <Text 
          fontSize={{base:"0.6rem", md:"0.8rem"}}
          fontWeight="500"
          color="black"
         >
        (Nomination closed)
         </Text>
      </Text>
      <Text
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        textAlign="center"
        fontSize={{base:"1rem", md:"1.2rem"}}
        fontWeight="600"
        color="#1C4779"
      >
        Vidhan Sabha 2023 
        <Text
         fontSize={{base:"0.7rem", md:"0.9rem"}}
         fontWeight="500"
         color="red"
        >
         Nov <Text color="black" display="inline"> 2023</Text>
      </Text>
          </Text>
      <Text
        position="absolute"
        bottom="10px"
        left="10px"
        fontWeight="600"
        fontSize={{base:"0.8rem", md:"1rem"}}
        color="black"
      >
        16th Election
      </Text>
      <Text
        position="absolute"
        bottom="10px"
        left="50%"
        transform="translateX(-50%)"
        fontWeight="600"
        fontSize={{base:"0.8rem", md:"1rem"}}
        color="#4D8AD1"
      >
        MEGA 28 Lakhs
      </Text>
      <Text
        position="absolute"
        bottom="10px"
        right="10px"
        fontWeight="600"
        fontSize={{base:"0.8rem", md:"1rem"}}
        color="#BA3F3F"
      >
        200 Seats
      </Text>
      <Divider 
      orientation="horizontal"
      margin="auto" 
      width="100%"
      position="absolute"
      bottom="21.5%"
      backgroundColor="#E0E0E0"
      />
    </Box>
        </SwiperSlide>
        <SwiperSlide>
        <Box
      // width="508px"
      width={{base:"80%", md:"508px"}}
      borderRadius="10px"
      margin="0 auto"
      position="relative"
      border="1px solid #333333"
      height={{base:"170px", md:"216px"}}
      background="white"
    >
     <Image
        src={imgLeft}
        alt="Left Image"
        style={{ position: "absolute", top: 0, left: 0 }}
        width={{base:"70%", md:"65%"}}
      />
      <Image
        src={imgRight}
        alt="Right Image"
        style={{ position: "absolute", top: 0, right: 0 }}
        width={{base:"50%", md:"45%"}}
      />
      <Image
        src={imgCenter}
        alt="Center Image"
        style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
        width={{base:"33%", md:"29%"}}
      />
      <Text
        position="absolute"
        top="10px"
        left="10px"
        // fontWeight="bold"
        fontSize={{base:"0.8rem", md:"1rem"}}
        fontWeight="500"
      >
        Rajasthan | INDIA
      </Text>
      <Text
        position="absolute"
        top="5px"
        right="8px"
        fontSize={{base:"0.8rem", md:"1rem"}}
        fontWeight="500"
        color="#09A347"
        // textAlign="right"
      >
        Nominees
        <br></br>
         <Text 
          fontSize={{base:"0.6rem", md:"0.8rem"}}
          fontWeight="500"
          color="black"
         >
        (Nomination closed)
         </Text>
      </Text>
      <Text
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        textAlign="center"
        fontSize={{base:"1rem", md:"1.2rem"}}
        fontWeight="600"
        color="#1C4779"
      >
        Vidhan Sabha 2023 
        <Text
         fontSize={{base:"0.7rem", md:"0.9rem"}}
         fontWeight="500"
         color="red"
        >
         Nov <Text color="black" display="inline"> 2023</Text>
      </Text>
          </Text>
      <Text
        position="absolute"
        bottom="10px"
        left="10px"
        fontWeight="600"
        fontSize={{base:"0.8rem", md:"1rem"}}
        color="black"
      >
        16th Election
      </Text>
      <Text
        position="absolute"
        bottom="10px"
        left="50%"
        transform="translateX(-50%)"
        fontWeight="600"
        fontSize={{base:"0.8rem", md:"1rem"}}
        color="#4D8AD1"
      >
        MEGA 28 Lakhs
      </Text>
      <Text
        position="absolute"
        bottom="10px"
        right="10px"
        fontWeight="600"
        fontSize={{base:"0.8rem", md:"1rem"}}
        color="#BA3F3F"
      >
        200 Seats
      </Text>
      <Divider 
      orientation="horizontal"
      margin="auto" 
      width="100%"
      position="absolute"
      bottom="21.5%"
      backgroundColor="#E0E0E0"
      />
    </Box>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
