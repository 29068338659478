import { useState , useEffect} from "react";
import { NavLink, useNavigate, Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import DIcon from "../../assets/downloadIcon.png";
import Hamburger from "hamburger-react";
import "./Navbar.css";

const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const navigate = useNavigate();

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const downloadFile = () => {
    // Replace 'your_file_url' with the actual URL of the file you want to download
    const fileUrl = "https://play.google.com/store/apps/details?id=io.ionic.ezivote";
    // Create a temporary anchor element
    const link = document.createElement("a");
    link.href = fileUrl;
    // Set the file name (you can extract it from the URL or provide a custom name)
    link.download = "filename";
    // Append the anchor to the document body
    document.body.appendChild(link);
    // Trigger the download
    link.click();
    // Clean up by removing the anchor
    document.body.removeChild(link);
  };

  const handleClickOutside = (event) => {
    const sidebar = document.querySelector(".nav-elements");
    const menuIcon = document.querySelector(".menu-icon");
    if (!sidebar.contains(event.target) && !menuIcon.contains(event.target)) {
      setShowNavbar(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  function clickHandler() {
    navigate("/download");
  }

  return (
    <nav className="navbar">
      <div className="container parent">
        <div className="logo">
          <Link to="/" cursor="pointer">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <Hamburger toggled={showNavbar} toggle={setShowNavbar} />
        </div>
        <div className={`nav-elements ${showNavbar ? "active" : ""}`}>
          <ul>
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li>
              <NavLink to="/about-us">About</NavLink>
            </li>
            <li>
              <NavLink to="/election-list">Election List</NavLink>
            </li>
            <li>
              <NavLink to="/trading-list">Trading List</NavLink>
            </li>
            <li>
              <NavLink to="/news">News</NavLink>
            </li>
            <li>
              <NavLink to="/contact-us">Contact us</NavLink>
            </li>
            <li>
              <NavLink to = "/download">Download Guide</NavLink>
            </li>
            <li className="btn-container">
              <button className="btn" onClick={downloadFile}>
              Download App
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
