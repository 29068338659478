import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "../Election/Cards/Cards.css";
import { GetTradingBannerAPI } from "../../Service";

const BannerSlider = () => {
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await GetTradingBannerAPI();
        setBanners(response || []);
        console.log(response, "banner");
      } catch (error) {
        console.error("Error fetching banners:", error);
      }
    };
    fetchBanners();
  }, []);

  return (
    <>
      <Swiper
        slidesPerView={1}
        loop={true}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        pagination={{ clickable: true }}
        navigation={true}
        className="mySwiper"
      >
        {banners.map((banner) => (
          <SwiperSlide key={banner.id}>
            <img
            className="banner-image"
              src={banner.image}
              alt={banner.title}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default BannerSlider;
