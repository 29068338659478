import React, { useEffect } from "react";
// import Banner from "../../assets/banner.png";
import Banner from "../../assets/bannerImages/contact.webp";
import Email from "../../assets/contactImage/email.png";
import Phone from "../../assets/contactImage/phone.png";
import Location from "../../assets/contactImage/location.png";
import Facebook from "../../assets/contactImage/facebook.png";
import Instagram from "../../assets/contactImage/insta.png";
import Linkedin from "../../assets/contactImage/linkedin.png";
import Pinterest from "../../assets/contactImage/pinterest.png";
import X from "../../assets/contactImage/x.png";
import { Link } from 'react-router-dom'
import { PhoneIcon } from '@chakra-ui/icons';
import {
  Box,
  Text,
  Grid,
  Divider,
  Flex,
  Stack,
  Input,
  Checkbox,
  Button,
  HStack,
  Image,
  VStack,
  Textarea,
  FormControl,
  useToast,
} from "@chakra-ui/react";

import { useState, useRef } from "react";
import axios from "axios";
import isEmpty from "is-empty";

const Contact = () => {
  const handleClick = () => {
    window.open("https://t.me/+ur0KIUX4qa1jYzU1", "_blank");
  };
  const REACT_API_URL = process.env.REACT_API_URL;

  const Toast = useToast();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  // const [isChecked, setIsChecked] = useState('true')

  function handleName(e) {
    setName(e.target.value);
  }
  function handlEmail(e) {
    setEmail(e.target.value);
  }

  function handleMessage(e) {
    setMessage(e.target.value);
  }
  // function handleCheckBox(e){
  //   setIsChecked(e.target.checked);
  //   console.log(e.target.isChecked, "This will print event")
  //   console.log(isChecked)
  // }

  function handleSubmit(e) {
    e.preventDefault();

    const formData = {
      name: name,
      email: email,
      message: message,
    };

    console.log(formData);
    // axios.post(`${REACT_API_URL}/submit-contact-us`, formData
    axios
      .post("https://api.ezivote.com/api/v1/user/submit-contact-us", formData)
      .then((response) => {
        console.log(response.data);

        Toast({
          description: "Form submitted successfully",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        // Handle error
      });
    setName("");
    setEmail("");
    setMessage("");
    // setIsChecked(false)
  }

  return (
    <Box
    >
      {/* <Box
        width="100%"
        height={{ base: "145px", md: "514px" }}
        backgroundImage={`url(${Banner})`}
        backgroundSize="cover"
        backgroundPosition="center"
      /> */}
      <img
        src={Banner}
        style={{
          widht: "100%",
          display: "block",
          height: "auto",
          padding: "0",
          margin: "0",
        }}
      />
      <Grid
        templateColumns={{
          base: "1fr",
          md: "repeat(1,1fr)",
          lg: "repeat(2, 1fr)",
          xl: "repeat(2, 1fr)",
        }}
        gap={12}
        px={{ base: 4, md: 8 }}
        mt={{ base: 4, md: 8 }}
        alignItems="center"
        height="100%"
      >
        <Stack
          width={{ base: "100%", lg: "557px" }}
          // width={{md:"400px", lg:"557px"}}
          height="820px"
          bg="white"
          mb={{ base: "50px" }}
          borderRadius="10px"
        // boxShadow="0px 4px 16px rgba(0, 0, 0, 0.08)"
        >
          <Text
            fontFamily="Montserrat"
            fontSize="35px"
            fontWeight="500"
            textAlign="left"
            mb={4}
          >
            Contact us
          </Text>
          <Text
            fontFamily="Montserrat"
            fontSize="20px"
            fontWeight="400"
            textAlign="left"
            mb={1}
            color="#696969"
          >
            Got questions, feedback, or just want to say hello? We'd love to
            hear from you!
          </Text>
          <Text
            fontFamily="Montserrat"
            fontSize="20px"
            fontWeight="400"
            textAlign="left"
            mb={4}
            color="#696969"
          >
            Reach out to us using the information below:
          </Text>
          <Divider mb={4} background="black" border="1px" />
          <Flex alignItems="flex-start" mb={4} flexDirection="column">
            <Box mb={8}>
              <HStack spacing={8}>
                <Image
                  src={Phone}
                  width="18%"
                  style={{ cursor: "pointer" }}
                  onClick={handleClick}
                />

                <VStack>
                  <Link
                    to="https://t.me/+ur0KIUX4qa1jYzU1"
                    target="blank"
                    style={{ cursor: "pointer" }}
                  >
                    <Text
                      fontFamily="Montserrat"
                      fontSize="20px"
                      fontWeight="500"
                      alignSelf="flex-start"
                      mb={1}
                    >
                      Telegram
                    </Text>
                  </Link>
                  <Text
                    fontFamily="Montserrat"
                    fontSize="16px"
                    fontStyle="italic"
                    fontWeight="500"
                    color="#595959"
                    alignSelf="flex-start"
                    onClick={handleClick}
                    cursor="pointer"
                  >
                    Join us
                  </Text>
                </VStack>
              </HStack>
            </Box>
            <Box mb={8}>
              <HStack spacing={8}>
                <Image src={Email} />
                <VStack>
                  <Text
                    fontFamily="Montserrat"
                    fontSize="20px"
                    fontWeight="500"
                    alignSelf="flex-start"
                  >
                    Email
                  </Text>
                  <Text
                    fontFamily="Montserrat"
                    fontSize="16px"
                    fontStyle="italic"
                    fontWeight="500"
                    color="#595959"
                    alignSelf="flex-start"
                    mb={2}
                  >
                    support@ezivote.com
                  </Text>
                </VStack>
              </HStack>
            </Box>
            <Box mb={8}>
              <HStack spacing={8}>
                <PhoneIcon w={6} h={6} color="blue.500" />
                <VStack>
                  <Text
                    fontFamily="Montserrat"
                    fontSize="20px"
                    fontWeight="500"
                    alignSelf="flex-start"
                  >
                    Phone
                  </Text>
                  <Text
                    fontFamily="Montserrat"
                    fontSize="16px"
                    fontStyle="italic"
                    fontWeight="500"
                    color="#595959"
                    alignSelf="flex-start"
                    mb={2}
                  >
                    +91 987 016 5276
                  </Text>
                </VStack>
              </HStack>
            </Box>
            <Box mb={4}>
              <HStack spacing={8}>
                <Image src={Location} />
                <VStack>
                  <Text
                    fontFamily="Montserrat"
                    fontSize="20px"
                    fontWeight="500"
                    alignSelf="flex-start"
                    mb={2}
                  >
                    Our Office Location
                  </Text>
                  <Text
                    fontFamily="Montserrat"
                    fontSize="16px"
                    fontStyle="italic"
                    fontWeight="500"
                    color="#595959"
                    lignSelf="flex-start"
                  >
                    Registered Address: Plot No. 713, Second Floor, Niti Khand-1, Indirapuram, Ghaziabad, Uttar Pradesh, 201014
                    <br /><br />
                    Corporate Office: 301/2 Sector 63 Noida, Uttar Pradesh 201301.
                  </Text>
                </VStack>
              </HStack>
            </Box>
          </Flex>
          <Text
            fontFamily="Montserrat"
            fontSize="16px"
            fontWeight="500"
            mb={2}
            color="black"
          >
            Follow us on social media for the latest updates, contests, and
            more:
          </Text>
          <Flex mb={5}>
            <HStack spacing={8}>
              <Link
                to="https://www.facebook.com/ezivote/"
                target="_blank"
                cursor="pointer"
              >
                <Image src={Facebook} alt="Facebook" />
              </Link>
              <Link
                to="https://www.linkedin.com/company/ezivote/"
                target="_blank"
                cursor="pointer"
              >
                <Image src={Linkedin} alt="Linkedin" />
              </Link>
              <Link
                to="https://www.pinterest.ca/ezivote/"
                target="_blank"
                cursor="pointer"
              >
                <Image src={Pinterest} alt="Pinterest" />
              </Link>
              <Link
                to="https://x.com/EzivotePlay"
                target="_blank"
                cursor="pointer"
              >
                <Image src={X} alt="Twitter" />
              </Link>
              <Link
                to="https://www.instagram.com/ezivoteofficial"
                target="_blank"
                cursor="pointer"
              >
                <Image src={Instagram} alt="Instagram" />
              </Link>

              {/* <Image src={Linkedin} />
              <Image src={Pinterest} />
              <Image src={X} />
              <Image src={Instagram} /> */}
            </HStack>
          </Flex>
          <Text
            fontFamily="Montserrat"
            fontSize="14px"
            fontWeight="500"
            textAlign="justify"
          >
            Our dedicated support team is here to assist you every step of the
            way. Don't hesitate to get in touch.
          </Text>
        </Stack>

        <Stack
          width={{ base: "100%", lg: "557px" }}
          height="auto"
          marginLeft={{ base: "0%", md: "10%" }}
          bg="white"
          p={{ base: 4, md: 6 }}
          borderRadius="10px"
          boxShadow="0px 4px 16px rgba(0, 0, 0, 0.08)"
          background="#EFEFEF"
          marginTop={{ base: "20", sm: "0px", md: "-70px" }}
          marginBottom={{ base: "4" }}
        >
          <Text
            fontFamily="Montserrat"
            fontSize="25px"
            fontWeight="400"
            textAlign="center"
            mb={4}
          >
            Get started with a free quotation
          </Text>
          <Stack spacing={4} mb={4}>
            <FormControl isRequired>
              <Text fontFamily="Montserrat" fontSize="16px" fontWeight="400">
                Name
              </Text>
              <Input
                type="text"
                name="name"
                value={name}
                onChange={handleName}
                placeholder="Enter your name"
                borderRadius="10px"
                height="60px"
                bg="white"
              />
            </FormControl>
            <FormControl isRequired>
              <Text fontFamily="Montserrat" fontSize="16px" fontWeight="400">
                Email
              </Text>
              <Input
                type="email"
                name="Email"
                value={email}
                onChange={handlEmail}
                placeholder="Enter your email"
                borderRadius="10px"
                height="60px"
                bg="white"
              />
            </FormControl>

            <FormControl isRequired>
              <Text fontFamily="Montserrat" fontSize="16px" fontWeight="400">
                Message
              </Text>
              <Textarea
                type="text"
                name="Message"
                value={message}
                onChange={handleMessage}
                placeholder="Enter your message"
                borderRadius="10px"
                height="147px"
                bg="white"
              />
            </FormControl>
          </Stack>
          {/* <FormControl isRequired>
          <Checkbox 
           mb={4}
           name="checkbox" 
           value="accepted"
           checked={isChecked} 
           onChange={handleCheckBox} 
          >I accept the Terms of Service</Checkbox>
          </FormControl> */}
          <Button
            bg="#3379CB"
            color="white"
            borderRadius="10px"
            height="50px"
            fontWeight="600"
            fontSize="20px"
            width="50%"
            alignSelf="center"
            _hover={{ bg: "blue.300" }}
            onClick={handleSubmit}
            isDisabled={isEmpty(name) || isEmpty(email) || isEmpty(message)}
          >
            SUBMIT
          </Button>
        </Stack>
      </Grid>
    </Box>
  );
};

export default Contact;
