import { Box, Text, Flex, Image, HStack } from "@chakra-ui/react";
// import Banner from "../../assets/banner.png";
import Banner from '../../assets/bannerImages/election.webp'
import LiveIcon from "../../assets/electionImage/live.png";
import backArrow from "../../assets/electionImage/barrow.png";
import forwardArrow from "../../assets/electionImage/farrow.png";
import slideImage from "../../assets/electionImage/slide.png";
import Cards from './Cards/Cards'
import Upcoming from './Cards/Upcoming'
import Past from './Cards/Past'

const Election = () => {
  return (
    <Box>
      {/* <Box
         width="100%"
         height={{ base: "145px", md: "514px" }}
         backgroundImage={`url(${Banner})`}
         backgroundSize="cover"
         backgroundPosition="center"
      /> */}
      <img src={Banner} style={{widht:"100%", display:"block",height:"auto",padding:"0", margin:"0"}}/>

      <Box>
        <Flex justify="center" flexDirection={{ base: "column", md: "column" }}>
          <HStack>
            <Text
              fontFamily="Montserrat"
              fontSize={{ base: "2xl", md: "4xl" }}
              fontWeight={500}
              lineHeight="43px"
              letterSpacing="0em"
              textAlign="left"
              marginTop={{ base: "20px", md: "70px" }}
              marginBottom={{ base: "20px", md: "30px" }}
              paddingLeft={{ base: "20px", md: "90px" }}
              paddingRight={{ base: "8px", md: "15px" }}
            >
              Live Elections
            </Text>
            <Image
              src={LiveIcon}
              alt="Live Icon"
              width={{ base: "12%", md: "60px", lg: "90px" }}
              marginTop={{ base: "5px", md: "50px" }}
            />
          </HStack>

          <Text
            mt={4}
            fontSize={{ base: "16px", md: "20px" }}
            fontFamily="Montserrat"
            fontWeight={400}
            lineHeight="25px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "90px" }}
            paddingRight={{ base: "20px", md: "90px" }}
            color="#696969"
          >
            Immerse yourself in the excitement of real-time political showdowns
            with Ezivote's Live Elections feature. Dive deep into the dynamic
            world of contemporary politics, where every move counts and every
            decision shapes the narrative. Stay on the cutting edge with live
            updates, trending hashtags, and interactive analysis, ensuring
            you're always one step ahead in the race to victory. Are you ready
            to unleash your political prowess and dominate the digital arena?
          </Text>
        </Flex>
        <Box marginTop={{base:"1rem", md:"3rem"}} 
        marginBottom={{base:"1rem", md:"3rem"}}
        widht={{base:"80%", md:"100%"}}
        >
        {/* <Cards/> */}
        <Upcoming/>
        </Box>
      </Box>
      <Box border="0 solid blue" backgroundColor="#2E74C6" paddingBottom="0.5rem">
        <Flex justify="center" flexDirection={{ base: "column", md: "column" }}>
          <HStack>
            <Text
              fontFamily="Montserrat"
              fontSize={{ base: "2xl", md: "4xl" }}
              fontWeight={500}
              lineHeight="43px"
              letterSpacing="0em"
              textAlign="left"
              marginTop={{ base: "20px", md: "70px" }}
              marginBottom={{ base: "20px", md: "30px" }}
              paddingLeft={{ base: "20px", md: "90px" }}
              paddingRight={{ base: "8px", md: "15px" }}
              color="#ffffff"
            >
              Upcoming Elections
            </Text>
          </HStack>

          <Text
            mt={4}
            fontSize={{ base: "16px", md: "20px" }}
            fontFamily="Montserrat"
            fontWeight={400}
            lineHeight="25px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "90px" }}
            paddingRight={{ base: "20px", md: "90px" }}
            color="#ffffff"
          >
            Stay ahead of the curve with Ezivote's Upcoming Elections section.
            Ride the wave of anticipation as you gear up for the next big
            political showdown, from local council battles to high-stakes
            national contests. Access insider insights, explore trending
            campaign strategies, and craft your winning playbook with precision.
            With Ezivote as your strategic ally, you'll be primed to seize the
            moment and make an indelible mark on the future of politics.
          </Text>
        </Flex>



        <Box 
        marginTop={{base:"1rem", md:"3rem"}} 
        marginBottom={{base:"1rem", md:"3rem"}}
        widht={{base:"80%", md:"100%"}}
        >
        <Upcoming/>
        </Box>
      
      </Box>
    
  
     <Box>
        <Flex justify="center" flexDirection={{ base: "column", md: "column" }}>
          <HStack>
            <Text
              fontFamily="Montserrat"
              fontSize={{ base: "2xl", md: "4xl" }}
              fontWeight={500}
              lineHeight="43px"
              letterSpacing="0em"
              textAlign="left"
              marginTop={{ base: "20px", md: "70px" }}
              marginBottom={{ base: "20px", md: "30px" }}
              paddingLeft={{ base: "20px", md: "90px" }}
              paddingRight={{ base: "8px", md: "15px" }}
            >
              Past Elections
            </Text>
          </HStack>
          <Text
            mt={4}
            fontSize={{ base: "16px", md: "20px" }}
            fontFamily="Montserrat"
            fontWeight={400}
            lineHeight="25px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "90px" }}
            paddingRight={{ base: "20px", md: "90px" }}
            color="#696969"
          >
            Rewind, reminisce, and revolutionize with Ezivote's Past Elections
            archive. Take a journey through the annals of political history,
            where each electoral triumph and setback has shaped the course of
            our nation. Uncover hidden gems, dissect viral moments, and glean
            invaluable lessons from the triumphs and tribulations of past
            leaders. With Ezivote's treasure trove of historical data and
            trending insights, the power to reshape the future lies in your
            hands.
          </Text>
        </Flex>

        <Box 
        marginTop={{base:"1rem", md:"3rem"}} 
        marginBottom={{base:"1rem", md:"3rem"}}
        widht={{base:"80%", md:"100%"}}
        >
        {/* <Cards/> */}
        <Past/>
        </Box>
      </Box>

    </Box>
  );
};

export default Election;
