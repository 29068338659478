import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Box, Text, Image, Button } from "@chakra-ui/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./Cards.css";
import SwiperCore from "swiper";
import "swiper/swiper-bundle.css";
import { Navigation } from "swiper/modules";

SwiperCore.use([Navigation]);

export default function TradingCard({ cards }) {
  const isMobile = window.matchMedia("(max-width: 992px)").matches;

  return (
    <Swiper
      navigation={true}
      slidesPerView={isMobile ? 1 : 2}
      className="mySwiper"
    >
      {cards?.map((card, index) => (
        <SwiperSlide key={index}>
          <CardComponent card={card} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

function CardComponent({ card }) {
  console.log(card, "cards");
  return (
    <Box className="card-container">
      <Text
        position="absolute"
        className={`h-30 ${
          card?.status === 1
            ? "green trading-green-gradient"
            : card?.status === 3
            ? "orange trading-orange-gradient"
            : ""
        }`}
      >
        {card?.status === 1
          ? "Active"
          : card?.status === 3
          ? "Completed"
          : card?.status}
      </Text>

      {/* Question and Hint */}
      <Text className="question-text">{card?.question}</Text>
      <Text className="hint-text">{card?.hint}</Text>

      {/* Image */}
      <Image src={card?.image} alt="Center Image" className="tradingCard-img" />

      {/* Option Button */}
      <div className="option-btn-container">
        {card.options?.map((option) => (
          <Button
            key={option.id}
            className={`option-btn ${
              option.option === "Yes"
                ? "yes-btn"
                : option.option === "No"
                ? "no-btn"
                : "bet-btn"
            }`}
          >
            <div className="option-btn-text">
              {option.option} ₹{option.price}
            </div>
          </Button>
        ))}
      </div>
    </Box>
  );
}
