// Card.js
import React from 'react';
import { Box, Image, Text, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

function Card({ data }) {
  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p="4"
      maxW="sm" 
      mx="auto"
      backgroundColor="#cfe2f3"
    >
      <Image src={data.image} alt={data.title} />
      <Box p="6">
        <Text fontWeight="bold" fontSize="20px" mb="2" >
          {data.title.slice(0,40)}...
        </Text>
        {/* <Text>{data.description.slice(0, 30)}...</Text> */}
        <Text className="news-article">
              <div dangerouslySetInnerHTML={{ __html: `${data.description.slice(0,60)}...` }} />

     </Text> 
        <Link to={`/news/${data.slug}`}>
          <Button mt="4" w="100%" backgroundColor="#327acc" color="white" _hover={{ color: 'black' }}>Read More</Button>
        </Link>
      </Box>
    </Box>
  );
}

export default Card;
