// DetailsPage.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Image, Text } from '@chakra-ui/react';
import axios from 'axios';
import './Blog.css'

function DetailsPage() {
  const { slug } = useParams();
  const [details, setDetails] = useState("");

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const response = await axios.get(`https://api.ezivote.com/api/v1/user/political-insights-detail/${slug}`);
        setDetails(response.data.data);
      } catch (error) {
        console.error('Error fetching details:', error);
      }
    };

    fetchDetails();
  }, [slug]);

  

  return (
    <Box  borderRadius="1px" p={{base:"1rem", md:"2rem"}} backgroundColor="#f4f4f4" padding={{base:"1rem", md:"5rem"}}>
    <Image src={details.image} alt={details.title} borderRadius="2px" width="100%" height="50%"/>
    <Box p="6">
      <Text fontWeight="bold" fontSize="lg" mb="2" fontFamily="Monteserrat" >
        {details.title}
      </Text>
      {/* <Text textAlign="justify">{details.description}</Text> */}
      <Text className="news-article">
              <div dangerouslySetInnerHTML={{ __html: details.description }} />

     </Text>
    </Box>
  </Box>
  );
}

export default DetailsPage;
