import { Box, Text, Flex, Image, HStack } from "@chakra-ui/react";
import {
  GetTradingBannerAPI,
  getDailyContestList,
  getMonthlyContestList,
  getWeeklyContestList,
} from "../../Service";
import { useEffect, useState } from "react";
import TradingCard from "./Cards/tradingCard";
import BannerSlider from "../Banner";

const Trading = () => {
  const [dailyContests, setDailyContests] = useState([]);
  const [weeklyContests, setWeeklyContests] = useState([]);
  const [monthlyContests, setMonthlyContests] = useState([]);
  const [banners, setBanners] = useState([]);

  const getDailyContests = async () => {
    try {
      const res = await getDailyContestList();
      console.log("Daily contests response:", res);
      setDailyContests(res);
    } catch (error) {
      console.error("Error fetching daily contests:", error);
    }
  };

  const getWeeklyContests = async () => {
    try {
      const res = await getWeeklyContestList();
      console.log("Weekly contests response:", res);
      setWeeklyContests(res);
    } catch (error) {
      console.error("Error fetching weekly contests:", error);
    }
  };

  const getMonthlyContests = async () => {
    try {
      const res = await getMonthlyContestList();
      console.log("Monthly contests response:", res);
      setMonthlyContests(res);
    } catch (error) {
      console.error("Error fetching monthly contests:", error);
    }
  };

  const getTradingBanners = async () => {
    try {
      const response = await GetTradingBannerAPI();
      setBanners(response.data.data.map((banner) => banner.image) || []);
    } catch (error) {
      console.error("Error fetching banners:", error);
    }
  };

  useEffect(() => {
    getDailyContests();
    getWeeklyContests();
    getMonthlyContests();
    getTradingBanners();
  }, []);

  return (
    <Box>
      <BannerSlider />

      <Box>
        <Flex justify="center" flexDirection={{ base: "column", md: "column" }}>
          <HStack>
            <Text
              fontFamily="Montserrat"
              fontSize={{ base: "2xl", md: "4xl" }}
              fontWeight={500}
              lineHeight="43px"
              letterSpacing="0em"
              textAlign="left"
              marginTop={{ base: "20px", md: "70px" }}
              marginBottom={{ base: "20px", md: "30px" }}
              paddingLeft={{ base: "20px", md: "90px" }}
              paddingRight={{ base: "8px", md: "15px" }}
            >
              Daily Trading
            </Text>
          </HStack>

          <Text
            mt={4}
            fontSize={{ base: "16px", md: "20px" }}
            fontFamily="Montserrat"
            fontWeight={400}
            lineHeight="25px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "90px" }}
            paddingRight={{ base: "20px", md: "90px" }}
            color="#696969"
          >
            The 'Daily Contest' provides a wonderful opportunity to monetize
            your skills on a daily basis. Share your opinion in the form of Yes
            or No for a chance to win real money. Go to Ezivote's opinion
            trading section and find questions related to daily happenings that
            grab your interest. From politics to sports and entertainment, we
            intend to address issues from all possible perspectives.
          </Text>
        </Flex>
        <Box
          marginTop={{ base: "1rem", md: "3rem" }}
          marginBottom={{ base: "1rem", md: "3rem" }}
          widht={{ base: "80%", md: "100%" }}
        >
          <TradingCard cards={dailyContests} />
        </Box>
      </Box>
      <Box
        border="0 solid blue"
        backgroundColor="#2E74C6"
        paddingBottom="0.5rem"
      >
        <Flex justify="center" flexDirection={{ base: "column", md: "column" }}>
          <HStack>
            <Text
              fontFamily="Montserrat"
              fontSize={{ base: "2xl", md: "4xl" }}
              fontWeight={500}
              lineHeight="43px"
              letterSpacing="0em"
              textAlign="left"
              marginTop={{ base: "20px", md: "70px" }}
              marginBottom={{ base: "20px", md: "30px" }}
              paddingLeft={{ base: "20px", md: "90px" }}
              paddingRight={{ base: "8px", md: "15px" }}
              color="#ffffff"
            >
              Weekly Trading
            </Text>
          </HStack>

          <Text
            mt={4}
            fontSize={{ base: "16px", md: "20px" }}
            fontFamily="Montserrat"
            fontWeight={400}
            lineHeight="25px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "90px" }}
            paddingRight={{ base: "20px", md: "90px" }}
            color="#ffffff"
          >
            Some events occur within a week. For all such events and incidents
            that pique your interest, we have a 'Weekly Contest' in which you
            can cash in on your opinion within a period of one week. Keep
            yourself up to date and choose your weekly interest. Politics,
            sports, entertainment, and more -Ezivote provides a place for every
            passionate player.
          </Text>
        </Flex>

        <Box
          marginTop={{ base: "1rem", md: "3rem" }}
          marginBottom={{ base: "1rem", md: "3rem" }}
          widht={{ base: "80%", md: "100%" }}
        >
          <TradingCard cards={weeklyContests} />
        </Box>
      </Box>

      <Box>
        <Flex justify="center" flexDirection={{ base: "column", md: "column" }}>
          <HStack>
            <Text
              fontFamily="Montserrat"
              fontSize={{ base: "2xl", md: "4xl" }}
              fontWeight={500}
              lineHeight="43px"
              letterSpacing="0em"
              textAlign="left"
              marginTop={{ base: "20px", md: "70px" }}
              marginBottom={{ base: "20px", md: "30px" }}
              paddingLeft={{ base: "20px", md: "90px" }}
              paddingRight={{ base: "8px", md: "15px" }}
            >
              Monthly Trading
            </Text>
          </HStack>
          <Text
            mt={4}
            fontSize={{ base: "16px", md: "20px" }}
            fontFamily="Montserrat"
            fontWeight={400}
            lineHeight="25px"
            letterSpacing="0.04em"
            textAlign="justify"
            paddingLeft={{ base: "20px", md: "90px" }}
            paddingRight={{ base: "20px", md: "90px" }}
            color="#696969"
          >
            If you believe that opinion trading is a game of skill as well as
            patience, 'Monthly Contest' is the right spot for you. Keep an eye
            on the major happenings unfolding on a monthly basis, and never miss
            a chance to express your opinion. Politics, sports, entertainment,
            and more -Monthly contest has covered it all.
          </Text>
        </Flex>

        <Box
          marginTop={{ base: "1rem", md: "3rem" }}
          marginBottom={{ base: "1rem", md: "3rem" }}
          widht={{ base: "80%", md: "100%" }}
        >
          <TradingCard cards={monthlyContests} />
        </Box>
      </Box>
    </Box>
  );
};

export default Trading;
