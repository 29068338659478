import Navbar from '../src/components/Navbar/Navbar'
import { ChakraProvider } from "@chakra-ui/react";

import {Routes, Route} from 'react-router-dom'
import Home from '../src/pages/Home/Home'
import About from '../src/pages/About/About'
import Election from '../src/pages/Election/Election'
import Blog from '../src/pages/Blog/Blog'
import Contact from '../src/pages/Contact/Contact'
import Download from '../src/pages/Download/Download'
import './App.css';
import Footer from './components/Footer/Footer';
import Policy from './pages/Policy/Policy'
import Disclaimer from './pages/Disclaimer/index'
import Terms from './pages/Terms/Terms'
import Points from './pages/Points/Points'
import DetailsPage from './pages/Blog/DetailsPage'
import Trading from './pages/Election/Trading';
import Refund from './pages/Refund';

function App() {
  return (
    <div >
      <ChakraProvider>
        <Navbar/>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/about-us" element={<About/>}/>
          <Route path="/election-list" element={<Election/>}/>
          <Route path="/trading-list" element={<Trading/>}/>
          <Route path="/news" element={<Blog/>}/>
          <Route path="/contact-us" element={<Contact/>}/>
          <Route path="/download" element={<Download/>}/>
          <Route path='/policy-privacy' element={<Policy/>}/>
          <Route path='/disclaimer' element={<Disclaimer/>}/>
          <Route path='/terms-and-conditions' element={<Terms/>}/>
          <Route path="/points" element={<Points/>}/>
          <Route path="/news/:slug" element={<DetailsPage />} />
          <Route path='/refund' element={<Refund/>}/>
        </Routes>
        <Footer/>
        </ChakraProvider>
    </div>
  );
}

export default App;
